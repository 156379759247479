// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from '@firebase/firestore'
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator } from "firebase/firestore";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: "vocabs.mpom.ch",
  projectId: "ai-vocabs-c98f4",
  storageBucket: "ai-vocabs-c98f4.appspot.com",
  messagingSenderId: "450203539187",
  appId: "1:450203539187:web:911e36d1dbc5b7762e8986",
  measurementId: "G-3JGGTKFZVT"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app)

export const auth = getAuth(app)

const functions = getFunctions(app);

if (import.meta.env.DEV) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  console.log("Connected to local functions emulator")

  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  console.log("Connected to local firestore emulator")

  connectAuthEmulator(auth, "http://localhost:9099");
  console.log("Connected to local auth emulator")
}
export default app;
