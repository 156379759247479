<script setup lang="ts">
import { RouterLink, RouterView } from 'vue-router'
import NavigationBar from './components/NavigationBar.vue'

import { inject, onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue'
import { useAuthStore } from '@/stores/auth'
import type { GlobalLoaderFunc } from '../types/custom-types'

const toggleGlobalLoader = inject('toggleGlobalLoader') as GlobalLoaderFunc

const hideContent = ref(false)

const auth = useAuthStore()

onBeforeMount(() => {
  auth.watchAuthState()
})

onUnmounted(() => {
  auth.unwatchAuthState()
})

onMounted(() => {
  // Avoid showing the login screen for users who are already logged in
  toggleGlobalLoader(true)
  hideContent.value = true

  window.setTimeout(() => {
    toggleGlobalLoader(false)
    hideContent.value = false
  }, 1000)
})
</script>

<template>
  <div>
    <div class="m-auto" style="max-width: 40rem">
      <nav class="sticky top-0 z-5">
        <NavigationBar class="my-2" />
      </nav>
      <template v-if="!hideContent">
        <RouterView />
      </template>
    </div>
    <Toast position="top-center" />
  </div>
</template>
<style>
body {
  margin: 1rem;
}
</style>
