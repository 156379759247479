<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import type { DatabaseWord } from '../../../types/custom-types'
import { getWords, deleteWord, updateWord, hideWord } from '../../services/wordService'
import Button from 'primevue/button'
import PlayVoice from '@/components/PlayVoice.vue'
import DataView from 'primevue/dataview'
import WordProgressBar from '@/components/WordProgressBar.vue'
import WandIcon from '@/components/WordManagement/WandIcon.vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { collection, doc, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '@/firebase/init'
import InputSwitch from 'primevue/inputswitch'
import { isLearned } from '@/utils'
import { Icon } from '@iconify/vue'
import AlphabetSelector from '@/components/WordManagement/AlphabetSelector.vue'

const words = ref<DatabaseWord[]>([])
const hideLearnedWords = ref(false)
const isLoaded = ref(false)
const selectedLetter = ref('')
let unsubscribe: () => void
let focussingDone = false

const ALPHABET_SELECTOR_LOWER_LIMIT = 30
const WORDSUGGESTION_ENABLED = true

const router = useRouter()

const emits = defineEmits(['editWord', 'addNewWord'])

const props = defineProps({
  focus: {
    type: Object as () => DatabaseWord,
    default: null
  }
})

// Fetch words from Firestore
const fetchWords = async () => {
  words.value = await getWords()
  isLoaded.value = true
}

// Edit word
const myEditWord = async (word: DatabaseWord) => {
  emits('editWord', word)
  await fetchWords() // Refresh the list
}

// Delete word
const myDeleteWord = async (id: string) => {
  await deleteWord(id)
  await fetchWords() // Refresh the list
}

const myHideWord = async (id: string, hide: boolean) => {
  await hideWord(id, hide)
}

const suggestWords = () => {
  router.push('/suggest-words')
}

defineExpose({
  fetchWords,
  focusToWord
})

// Initialize
fetchWords()

onMounted(() => {
  const auth = useAuthStore()
  hideLearnedWords.value = auth.dbUser!.vocabHideLearned

  const q = query(
    collection(db, 'words'),
    where('userRef', '==', doc(db, 'users/' + auth.user!.uid))
  )

  // TODO: This is duplicate code from fetchWords.
  unsubscribe = onSnapshot(q, (snapshot) => {
    words.value = snapshot.docs
      .filter((doc) => !!(doc.data() as DatabaseWord).word)
      .map((doc) => {
        return { id: doc.id, ...doc.data() } as DatabaseWord
      })
  })
})

const filteredWords = computed(() => {
  return words.value.filter((word: DatabaseWord) => {
    return (
      (!hideLearnedWords.value || !isLearned(word)) &&
      (!selectedLetter.value || word.word[0].toUpperCase() === selectedLetter.value)
    )
  })
})

watch([words], () => {
  if (props.focus && !focussingDone) {
    focusToWord(props.focus)
    focussingDone = true
  }
  if (words.value.length > ALPHABET_SELECTOR_LOWER_LIMIT && !selectedLetter.value) {
    selectedLetter.value = words.value
      .map((w) => w.word)
      .sort()[0][0]
      .toUpperCase()
  }
})

function focusToWord(word: DatabaseWord) {
  if (words.value.length > ALPHABET_SELECTOR_LOWER_LIMIT) {
    selectedLetter.value = word.word[0].toUpperCase()
  }
  nextTick(() => {
    const element = document.getElementById(word.word as string)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  })
}

watch([hideLearnedWords], () => {
  const auth = useAuthStore()
  auth.setSetting('vocabHideLearned', hideLearnedWords.value ?? false)
})

onUnmounted(() => {
  unsubscribe()
})
</script>
<template>
  <div v-if="isLoaded">
    <h1>Your Vocabulary</h1>
    <div v-if="words.length === 0">
      <p>
        You haven't added any vocabulary item. Start by adding your first item using the button
        below. Or let us suggest some words for you.
      </p>
      <img class="w-full" src="../../assets/empty-basket.jpg" />
      <div class="flex justify-content-around">
        <Button v-if="WORDSUGGESTION_ENABLED" class="my-4 font-bold" @click="suggestWords()">
          <WandIcon />
          Get Suggestions
        </Button>
        <Button
          class="my-4 ml-2"
          icon="pi pi-plus"
          @click="emits('addNewWord')"
          label="Add New Item"
        ></Button>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-row justify-content-end align-items-end">
        <Button v-if="WORDSUGGESTION_ENABLED" class="mb-2 font-bold" @click="suggestWords()">
          <WandIcon />
          Get Suggestions
        </Button>
        <Button
          class="mb-2 ml-2"
          icon="pi pi-plus"
          @click="emits('addNewWord')"
          label="Add New Item"
        ></Button>
      </div>
      <div
        v-if="words.length > ALPHABET_SELECTOR_LOWER_LIMIT"
        class="flex flex-row m-2 overflow-scroll"
      >
        <AlphabetSelector :words="words" v-model="selectedLetter" />
      </div>

      <div class="flex flex-row ml-2 align-items-center" v-if="words.length > 20">
        <div class="mr-2">
          <InputSwitch name="hideLearnedWords" v-model="hideLearnedWords" />
        </div>
        <div class="">
          <label for="hideLearnedWords" class="mr-1">Hide learned words</label>
        </div>
      </div>

      <DataView :value="filteredWords" :data-key="'id'" sortField="word" :sortOrder="1">
        <template #list="slotProps">
          <div class="col-12" :id="slotProps.data.word">
            <div class="flex flex-row">
              <div class="p-1 col-10" :class="{ 'text-gray-500': slotProps.data.hidden }">
                <div class="p-1 w-full">
                  <div class="flex flex-row justify-content-between align-items-center">
                    <div class="">
                      <b
                        >{{ slotProps.data.word }}&nbsp;
                        <PlayVoice
                          v-if="slotProps.data.voiceFilename"
                          :filename="slotProps.data.voiceFilename"
                        />
                        <Icon
                          v-if="slotProps.data.rarity >= 3"
                          class="mx-1"
                          style="vertical-align: text-top"
                          icon="system-uicons:diamond"
                          title="rare word"
                          height="20"
                        /> </b
                      ><br />
                      {{ slotProps.data.translation }}
                    </div>
                    <div class="w-4rem text-sm">
                      <WordProgressBar :value="slotProps.data.sm2interval" :word="slotProps.data" />
                    </div>
                  </div>
                </div>
                <template v-if="slotProps.data.explanation">
                  <div class="text-sm p-1">{{ slotProps.data.explanation }}</div>
                  <div class="text-sm p-1">«{{ slotProps.data.samplePhrase }}»</div>
                </template>
                <template v-else>
                  <div class="text-sm p-1"><i class="pi pi-clock"></i> Explanation pending...</div>
                </template>
              </div>
              <div class="col-2">
                <Button
                  text
                  @click="myEditWord(slotProps.data)"
                  size="small"
                  icon="pi pi-pencil"
                ></Button>
                <Button
                  text
                  @click="myDeleteWord(slotProps.data.id)"
                  size="small"
                  icon="pi pi-trash"
                ></Button>
                <Button
                  text
                  @click="myHideWord(slotProps.data.id, !slotProps.data.hidden)"
                  size="small"
                  :icon="'pi ' + (slotProps.data.hidden ? 'pi-eye' : 'pi-eye-slash')"
                ></Button>
              </div>
            </div>
          </div>
        </template>
      </DataView>
    </div>
  </div>
</template>
<style>
td {
  vertical-align: top;
}

.mainCol {
  font-weight: bold;
}

.smallCol {
  font-size: 0.7rem;
}

.buttonCol {
  white-space: nowrap;
}
</style>
