import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import type {
  DatabaseQuizSession,
  GPTResponseQuestion,
  SessionStoreWord
} from '../../types/custom-types'
import { db } from '@/firebase/init'
import { addDoc, collection, deleteDoc, doc } from 'firebase/firestore'
import { useAuthStore } from '@/stores/auth'
import { removePreparedSession } from '@/services/wordService'
import { getFunctions, httpsCallable } from 'firebase/functions'

const functions = getFunctions()

/**
 * This store is used to manage the current quiz session.
 *
 * Use it like this:
 * * Load the words you want to practice into the store with startSession()
 * * Use currentWord and currentQuestion to display the current question
 * * Use nextQuestion() to load the next question
 * * Use markAnswerAsCorrect() to mark the answer as correct or wrong
 * * Use storeSessionCompleted() to store the session in the database
 * Important: Session-Position starts a 1!
 */
export const useQuizSessionStore = defineStore('quizSessionStore', () => {
  const questionSequence = ref<SessionStoreWord[]>([])

  // The approach used here can be see in detail here: https://codepen.io/silvanm75/pen/OJdoemw
  const currentQuestion = ref<GPTResponseQuestion | null>(null)
  const preloadedQuestion = ref<GPTResponseQuestion | null>(null)
  const preloadPromise = ref<Promise<GPTResponseQuestion | null>>()

  // The index (starting at 1) of the question which is currently displayed
  const questionIndex = ref(0)
  const nextQuestionIndexToPreload = ref(0)
  const correctCount = ref(0)
  const wrongCount = ref(0)
  const suppressAudioQuestions = ref(false)
  const skipTypingQuestions = ref(false)
  const startScore = ref(0)
  const isForceMode = ref(false)
  const isSilentMode = ref(false)

  const currentWord = computed(() => {
    if (questionIndex.value > 0) {
      console.debug(
        'currentWord',
        questionIndex.value - 1,
        questionSequence.value[questionIndex.value - 1]
      )
      return questionSequence.value[questionIndex.value - 1]
    } else {
      console.debug('currentWord, edgeCase', 0, questionSequence.value[0])
      return questionSequence.value[0]
    }
  })

  const isNextQuestionAvailable = computed(() => {
    return !(nextQuestionIndexToPreload.value >= questionSequence.value.length)
  })

  /**
   * Shuffle the options of the question
   * @param options
   */
  function _shuffleOptions(options: string[]): string[] {
    const shuffledOptions = options.sort(() => Math.random() - 0.5)
    return shuffledOptions
  }

  /**
   * Preload the next question
   */
  async function _preloadNextQuestion() {
    if (nextQuestionIndexToPreload.value >= questionSequence.value.length) {
      return null
    }
    const wordToLoad = questionSequence.value[nextQuestionIndexToPreload.value].word
    const getQuizQuestion = httpsCallable(functions, 'getQuizQuestion')
    const result = (await getQuizQuestion({
      wordId: questionSequence.value[nextQuestionIndexToPreload.value].id,
      isSilentMode: isSilentMode.value
    })) as { data: GPTResponseQuestion }

    preloadedQuestion.value = result.data as GPTResponseQuestion
    preloadedQuestion.value.options = _shuffleOptions(preloadedQuestion.value.options)
    console.info(
      `loaded question from server (Question: ${preloadedQuestion.value?.question}, Index: ${nextQuestionIndexToPreload.value}, Word: ${wordToLoad})`
    )
    nextQuestionIndexToPreload.value++

    return preloadedQuestion.value
  }

  /**
   * Returns the next question. Returns false if the session is over.
   * Triggers a preload of the next question
   *
   * @returns false if no next question is available
   */
  async function nextQuestion() {
    if (questionIndex.value >= questionSequence.value.length) {
      return false
    }
    if (preloadedQuestion.value) {
      // The value is already ready
      console.log('The value is already ready')
      currentQuestion.value = preloadedQuestion.value
      preloadedQuestion.value = null
    } else {
      if (preloadPromise.value) {
        // a value is in preparation
        console.log('value in preparation')
        await preloadPromise.value
        currentQuestion.value = preloadedQuestion.value
        preloadedQuestion.value = null
      } else {
        console.log('value not yet in preparation')
        // The value is not ready and no promise ready
        await _preloadNextQuestion()
        currentQuestion.value = preloadedQuestion.value
      }
    }
    questionIndex.value++
    preloadPromise.value = _preloadNextQuestion()
    return currentQuestion.value
  }

  /**
   * Reset the session to the initial state.
   * Preload the first question.
   */
  function resetSession() {
    questionIndex.value = 0
    nextQuestionIndexToPreload.value = 0
    correctCount.value = 0
    wrongCount.value = 0
    currentQuestion.value = null
    preloadedQuestion.value = null
    preloadPromise.value = _preloadNextQuestion()
  }

  /**
   * Start a new session with the given words. Preload the first question.
   * But don't return the first question yet.
   *
   * @param words
   * @param startScore_
   */
  async function startSession(words: SessionStoreWord[], startScore_: number) {
    console.log('startSession')
    questionSequence.value = words
    shuffleWords()
    resetSession()
    startScore.value = startScore_
  }

  function shuffleWords() {
    console.log('Shuffle words')
    questionSequence.value.sort(() => Math.random() - 0.5)
  }

  function markAnswerAsCorrect(isAnswerCorrect: boolean) {
    questionSequence.value[questionIndex.value - 1].correct = isAnswerCorrect
    if (isAnswerCorrect) {
      correctCount.value++
    } else {
      wrongCount.value++
    }
  }

  async function storeSessionCompleted(endScore: number) {
    const coll = collection(db, 'quizSessions')
    const auth = useAuthStore()
    await removePreparedSession()
    return await addDoc(coll, {
      userRef: doc(db, 'users/' + auth.user!.uid),
      date: new Date().toISOString(),
      correctCount: correctCount.value,
      wrongCount: wrongCount.value,
      startScore: startScore.value,
      endScore: endScore
    } as DatabaseQuizSession)
  }

  const pointsCollected = computed(() => {
    return correctCount.value - wrongCount.value
  })

  return {
    questionSequence,
    questionIndex,
    nextQuestionIndexToPreload,
    correctCount,
    wrongCount,
    pointsCollected,
    suppressAudioQuestions,
    skipTypingQuestions,
    isForceMode,
    isSilentMode,
    isNextQuestionAvailable,
    nextQuestion,
    resetSession,
    markAnswerAsCorrect,
    storeSessionCompleted,
    shuffleWords,
    currentWord,
    currentQuestion,
    startScore,
    startSession
  }
})
