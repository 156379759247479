<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import DashboardView from '@/components/Home/DashboardView.vue'
import LoginView from '@/components/Home/LoginView.vue'
import OnboardingView from '@/components/Home/OnboardingView.vue'
import type { User } from 'firebase/auth'
import type { DatabaseUser } from '../../types/custom-types'

const auth = useAuthStore()
const { score, user, dbUser } = storeToRefs(auth) as unknown as {
  score: number
  user: User
  dbUser: DatabaseUser
}
</script>

<template>
  <div>
    <template v-if="user">
      <template v-if="dbUser?.isOnboarded">
        <DashboardView />
      </template>
      <template v-else>
        <OnboardingView />
      </template>
    </template>
    <template v-else>
      <LoginView />
    </template>
  </div>
</template>

<style scoped></style>
