<script setup lang="ts">
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import type { DatabaseWord } from '@/../types/custom-types'
import { onMounted, ref as _ref } from 'vue'
import PlayVoice from "@/components/PlayVoice.vue";

const props = defineProps({
  wordData: {
    type: Object as () => DatabaseWord,
    default: null
  }
})

const imageUrl = _ref('')

onMounted(async () => {
  const storage = getStorage()

  async function fetchDownloadUrl() {
    const fileRef = ref(storage, props.wordData?.imageFilename || '')

    const url = await getDownloadURL(fileRef)
    console.log(`Download URL: ${url}`)
    return url
  }

  imageUrl.value = await fetchDownloadUrl()
})
</script>
<template>
  <div class="my-3">
    <div><img class="w-full mb-1" :src="imageUrl" /></div>
    <p><em>«{{ props.wordData.samplePhrase }}»</em> <PlayVoice v-if="props.wordData?.voiceFilename" :filename="props.wordData?.voiceFilename" />
      <span class="text-gray-500"> — by Dall-E 3</span></p>
  </div>
</template>
