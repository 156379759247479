<script setup lang="ts">
import ProgressBar from 'primevue/progressbar'
import { INTERVAL_TO_DAY } from '@/config'
import type {DatabaseWord} from "../../types/custom-types";

const props = defineProps({
  value: Number,
  word: {
    type: Object as () => DatabaseWord,
    required: false
  }
})

// Make the progress start quickly and then slow down
function easeOutExpo(x: number): number {
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x)
}

function details() {
  if (!props.word) {
    return ''
  }
  return `SM2Ease: ${props.word?.sm2ease.toFixed(1)}.
  SM2Interval: ${ Math.floor(props.word.sm2interval) }.
   Last answer: ${ props.word.lastAnswer?.date }`
}

const getProgress = () => {
  if (!props.value) {
    return 0
  }
  return easeOutExpo(Math.min(props.value * INTERVAL_TO_DAY * 24 / 100), 1) * 100
}
</script>

<template>
  <ProgressBar
    :value="getProgress()"
    :show-value="false"
    v-tooltip.top.focus="{
      value: `How well you have memorized the item according to the SuperMemo 2 algorithm.
           (Details: ${details()})`,
      autoHide: false
    }"
    tabindex="1"
    style="height: 10px"
  />
</template>

<style scoped></style>
