<script setup lang="ts">
import WordIllustration from '@/components/Home/WordIllustration.vue'
import { onMounted, ref } from 'vue'
import { getWords } from '@/services/wordService'
import type { DatabaseWord } from '@/../types/custom-types'

const randomWord = ref<DatabaseWord | null>(null)

onMounted(async () => {
  try {
    const wordList = await getWords()
    const wordsWithImages = wordList.filter((word) => word.imageFilename)
    randomWord.value = wordsWithImages[
      Math.floor(Math.random() * wordsWithImages.length)
    ] as DatabaseWord
  } catch (error) {
    // Happens during logout
    console.info(error)
  }
})
</script>

<template>
  <WordIllustration v-if="randomWord" :wordData="randomWord" />
</template>
