<script lang="ts" setup>
import { ref } from 'vue'
import { parse } from 'papaparse'
import type { DatabaseWord } from '../../types/custom-types'
import {createWord} from "@/services/wordService";

const file = ref<File | null>(null)

const handleFileUpload = (event: Event) => {
  const input = event.target as HTMLInputElement
  if (input.files?.[0]) {
    file.value = input.files[0]
    parseCSV()
  }
}
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const parseCSV = () => {
  if (file.value) {
    parse(file.value, {
      header: true,
      complete: async (results) => {
        for (const row of results.data) {
          const word: DatabaseWord = {
            word: row.english,
            samplePhrase: row.example,
            translation: row.german,
            explanation: '',
            correct: 0,
            wrong: 0,
            sm2ease: 1.5,
            sm2interval: 1
          }
          await createWord(word)
          await delay(2000);
          console.log(row.english)
        }
      }
    })
  }
}

</script>

<template>
  <input type="file" accept=".csv" @change="handleFileUpload" />
</template>
