<script setup lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { Chart, registerables, type ChartData, type ChartOptions } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { LineChart } from 'vue-chart-3'
import { getFirestore, collection, query, where, getDocs, doc, orderBy } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const db = getFirestore()
const auth = getAuth()

Chart.register(...registerables)
Chart.defaults.font.family = '"Inter var", sans-serif'

let chartData: ChartData
let chartOptions: ChartOptions
let loaded = ref(false)

onMounted(async () => {
  const uid = auth.currentUser?.uid
  if (!uid) return

  const q = query(
    collection(db, 'quizSessions'),
    where('userRef', '==', doc(db, 'users/' + uid)),
    orderBy('date')
  )
  const querySnapshot = await getDocs(q)

  const labels: Date[] = []
  const data: number[] = []

  querySnapshot.forEach((doc) => {
    const docData = doc.data()
    labels.push(new Date(docData.date))
    data.push(docData.endScore)
  })

  chartData = {
    labels,
    datasets: [
      {
        label: 'Score',
        data,
        borderColor: '#6366f1',
        fill: {
          target: 'origin',
          above: '#a6a8f0' // Area will be red above the origin
        }
      }
    ]
  }

  chartOptions = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day'
        }
      },
      y: {
        beginAtZero: true
      }
    }
  }

  console.log(chartData)

  loaded.value = true
})
</script>
<template>
  <h1>See your Progress</h1>
  <!-- // @ts-ignore -->
  <LineChart v-if="loaded" :chartData="chartData" :options="chartOptions" />
</template>
