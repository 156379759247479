<script setup lang="ts">
import { inject, onMounted, ref } from 'vue'
import type { GlobalLoaderFunc } from '@/../types/custom-types'

const toggleGlobalLoader = inject('toggleGlobalLoader') as GlobalLoaderFunc
const triggerError = ref(false)

import { useSounds } from '@/composables/useSounds'
import { useQuizSessionStore } from '@/stores/quizSession'
import { storeToRefs } from 'pinia'
import { getFunctions, httpsCallable } from 'firebase/functions'
import type { SessionStoreWord } from '@/../types/custom-types'

const functions = getFunctions()
const quizSessionStore = useQuizSessionStore()

const {
  questionSequence,
  questionIndex,
  correctCount,
  wrongCount,
  currentWord,
  currentQuestion,
  suppressAudioQuestions,
  isNextQuestionAvailable
} = storeToRefs(quizSessionStore)

function toggleLoader() {
  toggleGlobalLoader(true, false, 'Loading...')
  setTimeout(() => {
    toggleGlobalLoader(false, false)
  }, 10000)
}

async function nextQuestion() {
  toggleGlobalLoader(true, false, 'Loading...')
  await quizSessionStore.nextQuestion()
  toggleGlobalLoader(false, false)
}

async function startSession() {
  const getQuestionSequence = httpsCallable(functions, 'getQuestionSequence')
  const result = await getQuestionSequence({ ignoreInterval: true })

  toggleGlobalLoader(true, false, 'Loading sequence')
  quizSessionStore.startSession(result.data as SessionStoreWord[], 100)
  toggleGlobalLoader(false, false)
}

onMounted(async () => {})
</script>
<template>
  <h1>Debug View</h1>
  <button @click="triggerError = !triggerError">Trigger Error</button>
  <button @click="toggleLoader">Toggle Loader</button>
  <button @click="useSounds().playWrong()">Play Sound</button>

  <button @click="startSession()">Start Session</button>
  <button @click="nextQuestion()">Next Question</button>

  <template v-if="currentWord && currentQuestion">
    <p><b>isNextQuestionAvailable</b>{{ isNextQuestionAvailable }}</p>
    <p><b>Pos:</b> {{ questionIndex }}</p>
    <p><b>current Word:</b> {{ currentWord.word }}</p>
    <p><b>current Question:</b> {{ currentQuestion.question }}</p>
  </template>
</template>

<style scoped></style>
