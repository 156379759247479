<script setup lang="ts">
import {computed, defineProps} from 'vue'
import type { DatabaseWord } from '../../../types/custom-types'

const props = defineProps({
  words: {
    type: Array as () => DatabaseWord[],
    default: () => []
  },
  modelValue: String,
})

const emit = defineEmits<{
  (event: 'update:modelValue', payload: string): void
}>()

const letters = computed(() => {
  const letters = new Set<string>()
  for (const word of props.words) {
    letters.add(word.word[0].toUpperCase())
  }
  return Array.from(letters).sort()
})

const click = (letter: string) => {
  emit('update:modelValue', letter)
}


</script>

<template>
  <ul class="w-full">
    <li v-for="letter in letters" :key="letter">
      <Button :text="modelValue != letter" :label="letter" @click="click(letter)"  />
    </li>
  </ul>
</template>


<style scoped>
ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  font-weight: bold
}

.p-button {
  padding: 0.1rem 0.2rem;
  margin: 0;
}

.p-button:focus {
  box-shadow: none;
}

</style>
