<script setup lang="ts">
import { ref, onMounted, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import Menubar from 'primevue/menubar'
import { useAuthStore } from '@/stores/auth'
import { useRouter } from 'vue-router'
import type { MenuItem } from 'primevue/menuitem'
import OdometerDisplay from '@/components/OdometerDisplay.vue'
import robotIcon from '@/assets/robot-line-icon.svg'

const auth = useAuthStore()
const router = useRouter()

const authStore = useAuthStore()
const { score, user } = storeToRefs(authStore)

const menuitems = ref<MenuItem[]>([])

watchEffect(async () => {
  if (user.value !== null) {
    let items: MenuItem[] = [
      {
        label: 'vocabs.AI',
        icon: 'custom-icon',
        to: '/'
      }
    ]

    if (authStore.dbUser?.isOnboarded) {
      items.push(
          {
            label: 'Practice',
            icon: 'pi pi-fw pi-play',
            to: '/practice'
          },
          {
            label: 'Vocabulary',
            icon: 'pi pi-fw pi-pencil',
            to: '/words'
          },
          {
            label: 'Settings',
            icon: 'pi pi-fw pi-cog',
            to: '/settings'
          }
      )
    }

    // await authStore.updateScore()

    items.push({
      label: 'About',
      icon: 'pi pi-fw pi-info',
      to: '/about'
    })

    items.push({
      label: 'Logout',
      icon: 'pi pi-fw pi-sign-out',
      command: () => {
        auth.logout()
        router.push('/')
      }
    })

    if (import.meta.env.VITE_DEBUG_MODE) {
      items.push({
        label: 'Debug',
        icon: 'pi pi-fw pi-bug',
        to: '/debug'
      })
    }
    menuitems.value = items
  }
})

onMounted(async () => {})
</script>

<template>
  <div class="card relative z-2">
    <Menubar :model="menuitems">
      <template #start>
        <RouterLink to="/">
          <div class="flex flex-row align-items-center mr-1">
            <div class="custom-icon"></div>
            <div v-if="!user" class="font-medium mx-1">Vocabs.AI</div>
          </div>
        </RouterLink>
      </template>
      <template #end>
        <transition name="fade">
          <div v-if="user && score > 0" class="white-space-nowrap flex text-xl">
            <i class="pi pi-bolt text-lg"></i>
            <OdometerDisplay :value="score"></OdometerDisplay>
          </div>
        </transition>
      </template>
    </Menubar>
  </div>
</template>

<style>
.custom-icon {
  background-image: url('@/assets/logo.svg');
  background-size: contain; /* Adjust as needed */
  background-repeat: no-repeat;
  width: 28px; /* Set the width */
  height: 28px; /* Set the height */
}

.p-menubar .router-link-active {
  text-decoration: none;
  color: inherit;
  display: flex;
}

@media screen and (max-width: 3000px) {
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border-radius: 50%;
    transition:
      background-color 0.2s,
      color 0.2s,
      box-shadow 0.2s;
  }

  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }

  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
}
</style>
