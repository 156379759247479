<script setup lang="ts">
import { defineComponent, nextTick, ref } from 'vue'
import WordDataTable from '@/components/WordManagement/WordDataView.vue'
import WordForm from '@/components/WordManagement/WordForm.vue'
import type { DatabaseWord } from '../../types/custom-types'

const selectedWord = ref<DatabaseWord | null>(null)
const wordDataTable = ref<typeof WordDataTable | null>(null)
const focusedWord = ref<DatabaseWord | null>(null)

const addNewWord = () => {
  selectedWord.value = {} as DatabaseWord // Initialize a new empty DatabaseWord object
}

const editWord = (word: DatabaseWord) => {
  selectedWord.value = word
}

const fetchWords = () => {
  wordDataTable.value?.fetchWords()
}

const closeForm = (word: DatabaseWord) => {
  console.log('closeForm', word)
  selectedWord.value = null
  focusedWord.value = word
}

const visible = ref(false)
</script>
<template>
  <div>
    <WordForm v-if="selectedWord" :wordData="selectedWord" @refresh="fetchWords" @close="closeForm" />
    <div v-if="!selectedWord">
      <WordDataTable ref="wordDataTable" @editWord="editWord" @refresh="fetchWords" @addNewWord="addNewWord"
        :focus="focusedWord" />
    </div>
    <router-link to="/import">Import</router-link>
  </div>
</template>
