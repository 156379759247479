<script setup lang="ts">
import { LANGUAGES, SPEAKERS } from '@/config'
import { onMounted, ref } from 'vue'
import Dropdown from 'primevue/dropdown'
import InputSwitch from 'primevue/inputswitch'
import InfoTooltip from '@/components/InfoTooltip.vue'
import { useAuthStore } from '@/stores/auth'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@/firebase/init'
import { useToast } from 'primevue/usetoast'
import SpeakerSelector from "@/components/SpeakerSelector.vue";

const selectedLanguage = ref(LANGUAGES[0])
const suppressAudioQuestions = ref(false)
const speaker = ref()

const authStore = useAuthStore()

const toast = useToast()

async function save() {
  const auth = useAuthStore()
  const docRef = doc(db, 'users', auth.user!.uid)
  await updateDoc(docRef, {
    language: selectedLanguage.value.value,
    silentMode: suppressAudioQuestions.value,
    speaker: speaker.value.value,
    isOnboarded: true
  })

  toast.add({
    severity: 'success',
    summary: 'Settings saved.',
    life: 5000
  })
}

onMounted(() => {
  selectedLanguage.value = LANGUAGES.find((lang) => lang.value === authStore.dbUser!.language)!
  suppressAudioQuestions.value = authStore.dbUser!.silentMode ?? false
})
</script>
<template>
  <h1>Settings</h1>
  <table>
    <tr>
      <td class="vertical-align-middle">
        <label for="language">Your native language</label>
      </td>
      <td class="controls">
        <Dropdown
          v-model="selectedLanguage"
          :options="LANGUAGES"
          optionLabel="label"
          placeholder="Select a Language"
        />
      </td>
    </tr>
    <tr>
      <td class="vertical-align-middle">
        <label for="language">Speaker </label>&nbsp;<InfoTooltip
          text="The voice will only be applied to new words."
        />
      </td>
      <td class="controls">
        <SpeakerSelector v-model="speaker" />
      </td>
    </tr>
    <tr>
      <td class="vertical-align-middle">
        <label for="suppressAudio"
          >Silent mode&nbsp;<InfoTooltip
            text="Disable the questions that necessitate verbal responses. This is useful in public environments"
          />
        </label>
      </td>
      <td class="controls">
        <InputSwitch name="suppressAudio" v-model="suppressAudioQuestions" />
      </td>
    </tr>
    <tr>
      <td></td>
      <td class="controls my-3">
        <Button label="Save" @click="save" />
      </td>
    </tr>
  </table>
</template>
<style scoped>
td {
  padding-bottom: 0.5rem;
}

.controls {
  padding-left: 1rem;
}
</style>
