<script setup lang="ts">
import { ref, watchEffect } from 'vue'
import { createWord, updateWord } from '@/services/wordService' // Assuming you have a service file for API calls
import { getFunctions, httpsCallable } from 'firebase/functions'
import type { DatabaseWord, GPTResponseGenerateDefinition } from '../../../types/custom-types'
import { useAuthStore } from '@/stores/auth'
import { LANGUAGES } from '@/config'
import WandIcon from "@/components/WordManagement/WandIcon.vue";
import WordIllustration from "@/components/Home/WordIllustration.vue";

const props = defineProps({
  wordData: {
    type: Object as () => DatabaseWord,
    default: null
  }
})

const emits = defineEmits(['refresh', 'close'])

const word = ref<DatabaseWord>(props.wordData || ({} as DatabaseWord))
const showDialog = ref(false)
const isGenerating = ref(false)

const saveWord = async () => {
  await (word.value.id ? updateWord(word.value) : createWord(word.value))
  showDialog.value = false
  emits('close', word.value)
}

const closeDialog = (addedWord:DatabaseWord) => {
  showDialog.value = false

  // Emit the event with the word data
  emits('close', word.value)
  word.value = {} as DatabaseWord // Reset the form
}

const generateDefinition = async () => {
  console.log(isGenerating.value)
  isGenerating.value = true
  try {
    const functions = getFunctions()
    const getDefinition = httpsCallable(functions, 'generateDefinition')
    const payload = {
      word: word.value.word,
      definition: word.value.explanation,
      sampleSentence: word.value.samplePhrase,
      translation: word.value.translation
    }
    const result = await getDefinition(payload)
    const data = result.data as GPTResponseGenerateDefinition
    word.value.word = data.word
    word.value.explanation = data.definition
    word.value.translation = data.translation
    word.value.samplePhrase = data.sampleSentence
    word.value.rarity = data.rarity
  } finally {
    isGenerating.value = false
  }
}

const getPlaceholder = () => {
  const auth = useAuthStore()
  return (
    LANGUAGES.find((lang) => lang.value === auth.dbUser?.language)?.placeholderTranslation ?? ''
  )
}

const imageUrl = ref('')

watchEffect(() => {
  if (props.wordData) {
    word.value = props.wordData

    showDialog.value = true // Show the dialog when wordData prop changes
  }
})
</script>

<template>
  <div>
    <h2>{{ word.id ? 'Edit Vocab Item' : 'Add New Vocab Item' }}</h2>

    <p>
      Fill in any fields you want let AI fill in the other fields by clicking on
      <a href="#" @click="generateDefinition">Generate</a>. It can be either a single word or a
      phrase.
    </p>

    <div>
      <label for="word" class="block text-900 font-medium mt-1 mb-1">Word or Phrase</label>
      <InputText
        id="word"
        v-model.trim="word.word"
        class="w-full mb-2"
        maxlength="100"
        placeholder="apple, cozy, all bark and no bite"
      />
      <label for="explanation" class="block text-900 font-medium mt-1 mb-1">Explanation
        <span class="optional">(optional)</span>
      </label>
      <Textarea
        id="explanation"
        v-model.trim="word.explanation"
        rows="3"
        maxlength="300"
        class="w-full mb-2"
        placeholder="click on 'Generate'"
      />
      <label for="samplePhrase" class="block text-900 font-medium my-1">Sample Phrase
        <span class="optional">(optional)</span>
      </label>

      <Textarea
        id="samplePhrase"
        v-model.trim="word.samplePhrase"
        rows="3"
        maxlength="300"
        class="w-full mb-2"
        placeholder="click on 'Generate'"
      />
      <label for="translation" class="block text-900 font-medium my-1">Translation
      <span class="optional">(optional)</span>
      </label>
      <InputText
        id="translation"
        v-model.trim="word.translation"
        class="w-full mb-2"
        maxlength="100"
        placeholder="click on 'Generate'"
      />
    </div>
    <input type="hidden" name="rarity" v-model="word.rarity" />

    <div class="flex flex-row justify-content-end w-full">
      <Button
        label="Generate"
        class="font-bold"
        @click="generateDefinition"
        :disabled="word.word == ''"
        :loading="isGenerating"
      >
        <i v-if="isGenerating" class="pi pi-spin pi-spinner mr-2"></i>
        <WandIcon v-if="!isGenerating" />
        Generate
      </Button>

      <Button label="Cancel" class="ml-2" link @click="closeDialog" />
      <Button label="Save" class="ml-2" :disabled="word.word == ''" @click="saveWord" />

    </div>
    <WordIllustration v-if="word.imageFilename" :wordData="word" />
  </div>
</template>

<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
}

.form-field {
  margin-bottom: 16px;
}

.optional {
  color: #999;
}

label {
  display: inline-block;
  margin-bottom: 8px;
  font-weight: bold;
}
</style>
