<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'

const auth = useAuthStore()

const { user } = storeToRefs(auth)

const handleLoginWithGoogleClick = async () => {
  await auth.loginInWithGoogle()
}

const handleLoginWithAppleClick = async () => {
  await auth.loginWithApple()
}
</script>

<template>
  <div>
    <div>
      <template v-if="user"></template>
      <template v-else>
        <div class="grid">
          <div class="col">
            <Button @click="handleLoginWithGoogleClick" class="w-full justify-content-center">
              <i class="pi pi-google mr-2"></i>
              Login with Google
            </Button>
          </div>
          <div class="col">
            <Button
              @click="handleLoginWithAppleClick"
              class="w-full flex-grow-1 justify-content-center"
            >
              <i class="pi pi-apple mr-2"></i>
              Login with AppleID
            </Button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
