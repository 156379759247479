import {INTERVAL_TO_DAY} from "@/config";
import type {DatabaseWord} from "../types/custom-types";

export async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = function () {
      const base64 = (reader.result as string).split(',')[1]
      resolve(base64)
    }
    reader.readAsDataURL(blob)
  })
}

export function calcSm2Rating(timerProgress: number) {
  return Math.min(5, Math.floor(((timerProgress + 20) / 120) * 5 + 1))
}

export function isLearned(word: DatabaseWord): boolean {
  return Math.floor(word.sm2interval * INTERVAL_TO_DAY * 24) > 100
}

