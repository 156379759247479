<script setup lang="ts">
import Badge from 'primevue/badge'
import Dropdown from 'primevue/dropdown'
import { ref, watch } from 'vue'
import { auth, db } from '@/firebase/init'
import { doc, updateDoc } from 'firebase/firestore'

import {LANGUAGES, SPEAKERS} from '@/config'
import { useRouter } from 'vue-router'
import {useAuthStore} from "@/stores/auth";
import SpeakerSelector from "@/components/SpeakerSelector.vue";

const selectedLanguage = ref(LANGUAGES[0])

const router = useRouter()
const authStore = useAuthStore()
const speaker = ref()

async function storeLanguage() {
  authStore.subscribeToUserUpdates()
  const auth = useAuthStore()
  const docRef = doc(db, 'users', auth.user!.uid)
  await updateDoc(docRef, {
    language: selectedLanguage.value.value,
    speaker: speaker.value.value,
    isOnboarded: true
  })
}

async function goToManageWords() {
  await storeLanguage()
  router.push('/words')
}
</script>
<template>
  <div>
    <h1>Let's Get Started</h1>

    <div class="flex">
      <div class="p-2">
        <Badge value="1"></Badge>
      </div>
      <div class="p-2">
        <p class="mt-0">Choose your native language</p>

        <Dropdown
          v-model="selectedLanguage"
          :options="LANGUAGES"
          optionLabel="label"
          placeholder="Select a Language"
        />
      </div>
    </div>
    <div class="flex">
      <div class="p-2">
        <Badge value="2"></Badge>
      </div>
      <div class="p-2">
        <p class="mt-0">Pick a speaker</p>
        <SpeakerSelector v-model="speaker" />
      </div>
    </div>
    <div class="flex">
      <div class="p-2">
        <Badge value="3"></Badge>
      </div>
      <div class="p-2">
        Add some words or phrases by clicking on "<i class="pi pi-pencil"></i>Vocabulary" in the menu.
      </div>
    </div>

    <div class="flex">
      <div class="p-2">
        <Badge value="4"></Badge>
      </div>
      <div class="p-2">
        Start your practice by clicking on "<i class="pi pi-play"></i>Practice" in the menu.
      </div>
    </div>

    <Button class="w-full my-4 flex-grow-1 justify-content-center" @click="goToManageWords"
      >Let's Go
    </Button>

    <img src="@/assets/race.jpg" class="w-full" />
  </div>
</template>
