<script setup lang="ts">
import { ref, defineProps, defineEmits, onMounted } from 'vue'


// Props and Emits
const emit = defineEmits(['expired', 'progress'])
defineExpose({ start, stop, reset })

// State
const remainingTime = ref(0)
const initialDuration = ref(0)
const progress = ref(100)

// Countdown Logic
const intervalId = ref<number | null>(null)

const updateProgress = () => {
  progress.value = (remainingTime.value / initialDuration.value) * 100
  emit('progress', progress.value)
}

function start(duration: number) {
  if (intervalId.value !== null) {
    clearInterval(intervalId.value)
  }
  console.log('countdown started')
  initialDuration.value = duration
  remainingTime.value = duration

  intervalId.value = window.setInterval(() => {
    if (remainingTime.value > 0) {
      remainingTime.value--
      updateProgress()
    } else {
      clearInterval(intervalId.value!)
      emit('expired')
    }
  }, 1000)
}

function stop() {
  if (intervalId.value !== null) {
    clearInterval(intervalId.value)
  }
}

function reset() {
  stop()
  remainingTime.value = initialDuration.value
  updateProgress()
}

// Watch for prop changes
onMounted(() => {
  remainingTime.value = initialDuration.value
  updateProgress()
})
</script>
<template>
  <div class="progress-container rounded flex flex-row align-items-end">
    <div
      :style="{ width: `${progress}%` }"
      class="progress-bar"
      :class="{ full: progress === 100 }"
    ></div>
  </div>
</template>
<style scoped>
.progress-container {
  width: 100%;
  background-color: #ccc;
  border-radius: 6px;
}

.progress-bar {
  height: 20px;
  background-color: var(--green-800);
  transition: all 1s linear;
  border-radius: 6px 0 0 6px;
}

.progress-bar.full {
  border-radius: 6px;
}
</style>
