<script setup lang="ts">
import { getStorage, ref as _ref, getDownloadURL } from 'firebase/storage'
import { ref, watch } from 'vue'
import AudioPlayer from '@liripeng/vue-audio-player'

const props = defineProps({
  filename: {
    type: String,
    required: true
  },
  isFullPlayer: {
    type: Boolean,
    default: false
  }
})

let url = ref('')
let isPlaying = ref(false)

async function play() {
  // Don't play if already playing
  if (isPlaying.value) {
    return
  }
  const audio = new Audio(url.value)
  await audio.play()
  isPlaying.value = true

  audio.addEventListener('ended', () => {
    isPlaying.value = false
  })
}

watch(isPlaying, (newVal) => {
  if (newVal) {
    play()
  }
})

watch(
  () => props.filename,
  async () => {
    const storage = getStorage()

    async function fetchDownloadUrl() {
      const fileRef = _ref(storage, props.filename)

      return await getDownloadURL(fileRef)
    }

    url.value = await fetchDownloadUrl()
  },
  { immediate: true }
)
</script>

<template>
  <template v-if="isFullPlayer">
    <audio-player
      ref="audioPlayer"
      :audio-list="[url]"
      theme-color="#6366f1"
      :show-prev-button="false"
      :show-next-button="false"
      :show-volume-button="false"
      :show-playback-rate="false"
      :isLoop="false"
      :progress-interval="100"
    />
  </template>
  <template v-else>
    <i
      class="pi pi-volume-up cursor-pointer play-button"
      :class="{ playing: isPlaying }"
      @click="play"
    ></i>
  </template>
</template>

<style>
.audio-player .audio__progress-wrap {
  background: #ccc !important;
  border-radius: 0 !important;
  height: 7px !important;
}

.audio-player .audio__time-wrap {
  display: none !important;
}

.audio-player .audio__progress-point {
  box-shadow: none !important;
}

@keyframes pulse {
  0% {
    content: '\e977';
  }
  50% {
    content: '\e976';
  }
}

/* alternating two classes for the same element as animation */
.play-button.playing::before {
  animation: pulse 2s infinite;
}
</style>
