<script setup lang="ts">
import {ref, computed, nextTick, onMounted} from 'vue'
import { useToast } from 'primevue/usetoast'
import AudioVisualizer from '@/components/Quiz/AudioVisualizer.vue'

// enum storing the app state
enum AppState {
  Ready,
  Recording,
  Processing,
  Done
}

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['start', 'stop', 'done'])

defineExpose({
  toggleRecording
})

const toast = useToast()

const MAX_RECORDING_TIME = 30 * 1000

//const recordButton = ref<HTMLButtonElement | null>(null);
const statustext = ref('Not recording')
const audioVisualizer = ref<typeof AudioVisualizer | null>(null)
const stream = ref<MediaStream | null>(null)
const amplitude = ref<number>(0)
const resultTranscript = ref<string>('')
const resultLevel = ref<string>('')
const resultComment = ref<string>('')
const resultImproved = ref<string>('')

const appState = ref<AppState>(AppState.Ready)
//const audioVisualizer = ref<AudioVisualizer | null>(null);

let mediaRecorder: MediaRecorder | null = null
let chunks: BlobPart[] = []
let recordingTimer: number | null = null

function resetFields() {
  resultTranscript.value = ''
  resultComment.value = ''
  resultLevel.value = ''
  resultImproved.value = ''
}

function handleException() {
  toast.add({
    severity: 'error',
    summary: 'An error occured. Please try again later'
  })
  resetFields()
  appState.value = AppState.Ready
}

function addEventListeners(): void {}

function initWebSocket(): void {
  addEventListeners()
}

// Initialize the MediaStream for recording
async function initMediaStream() {
  try {
    stream.value = await navigator.mediaDevices.getUserMedia({ audio: true })
    mediaRecorder = new MediaRecorder(stream.value)
    mediaRecorder.ondataavailable = (e: BlobEvent) => chunks.push(e.data)
    mediaRecorder.onstop = saveRecording
    chunks = []
  } catch (err) {
    console.error('Error initializing media stream:', err)
  }
}

// Start recording
async function startRecording() {
  await initMediaStream()
  initWebSocket()
  resetFields()
  if (mediaRecorder) {
    appState.value = AppState.Recording
    mediaRecorder.start(1000)
    nextTick(() => {
      // audioVisualizer.value.reset();
      if (audioVisualizer.value)
        audioVisualizer.value.start();
    })
    recordingTimer = window.setTimeout(() => {
      if (appState.value === AppState.Recording) {
        stopRecording()
      }
    }, MAX_RECORDING_TIME)
  }
}

function stopRecording() {
  if (mediaRecorder) {
    // audioVisualizer.value.stop();
    appState.value = AppState.Ready
    if (recordingTimer) {
      window.clearTimeout(recordingTimer)
    }
    if (mediaRecorder) {
      audioVisualizer.value?.stop()
      mediaRecorder.stop()
    }
  }
}

async function saveRecording() {
  if (mediaRecorder) {
    const blob = new Blob(chunks, { type: mediaRecorder.mimeType })
    await sendBlobToServer(blob, mediaRecorder.mimeType)

    // Stops the indicator
    stream.value?.getTracks().forEach((track) => track.stop())
  }
}

async function sendBlobToServer(blob: Blob, mimeType: string): Promise<void> {
  statustext.value = 'Send file to server...'

  emits('stop', { blob, mimeType })
}

function toggleRecording() {
  if (appState.value === AppState.Processing) {
    return
  }
  if (appState.value === AppState.Recording) {
    stopRecording()
  } else {
    emits('start')
    window.setTimeout(() => {
      startRecording()
    }, 1000)
  }
}

function updateAmplitude(amp: number) {
  amplitude.value = amp
}
</script>
<template>
  <div class="flex justify-content-center">
    <Button
      id="recording-button"
      icon="pi pi-microphone"
      :style="{ transform: `scale(${1.2 + amplitude / 2} )` }"
      rounded
      size="large"
      :severity="appState === AppState.Recording ? 'danger' : 'primary'"
      :class="[appState === AppState.Recording ? 'active' : '']"
      :show-stop-icon="appState === AppState.Recording"
      :disabled="props.disabled || appState === AppState.Processing"
      @click="toggleRecording"
    />
    <AudioVisualizer ref="audioVisualizer" v-if="appState == AppState.Recording" :stream="stream as MediaStream"
    @sound="updateAmplitude"
    />
  </div>
</template>
<style scoped>
#recording-button {
  margin-top: 10px;
  transform: scale(1.2);
  transition: transform 50ms ease-in-out;
}
</style>
