<script setup lang="ts">
import InfoTooltip from '@/components/InfoTooltip.vue'
import Dropdown from 'primevue/dropdown'
import InputSwitch from 'primevue/inputswitch'

defineProps({
  domain: {
    type: String,
    default: ''
  },
  wantIdioms: {
    type: Boolean,
    default: false
  },
  level: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(['update:domain', 'update:wantIdioms', 'update:level', 'submit'])

const quizDomains: string[] = [
  'anything',
  'Business & Economics',
  'Celebrity Gossip',
  'History & Politics',
  'Hollywood Movies',
  'Literature & Language',
  'Science & Nature',
  'Star Wars',
  'Sports & Recreation',
  'Technology & Programming',
  'The Simpsons',
  'Video Games'
]

const cambridgeLevels: { name: string; code: string }[] = [
  { name: 'A1 - Beginner', code: 'A1' },
  { name: 'A2 - Elementary', code: 'A2' },
  { name: 'B1 - Intermediate', code: 'B1' },
  { name: 'B2 - Upper-Intermediate', code: 'B2' },
  { name: 'C1 - Advanced', code: 'C1' },
  { name: 'C2 - Proficiency', code: 'C2' }
]
</script>

<template>
  <p class="">Let ChatGPT generate items for your vocabulary.</p>
  <div class="field my-4">
    <label for="domain" class="font-medium"
      >Domain of the items
      <InfoTooltip text="You can type in your own domain, e.g. 'The Simpsons'" />
      <span class="text-gray-500">(optional)</span>
    </label>
    <Dropdown
      id="domain"
      class="w-full"
      :model-value="domain"
      @update:modelValue="emits('update:domain', $event)"
      editable
      :options="quizDomains"
      placeholder="Select a Domain"
    />
  </div>
  <div class="field my-4">
    <label for="wordcount" class="font-medium">Type of items</label>
    <div class="flex flex-row">
      <div class="m-2 align-items-center">Words</div>
      <InputSwitch
        class="align-items-center"
        :model-value="wantIdioms"
        @update:model-value="emits('update:wantIdioms', $event)"
      />
      <div class="m-2 align-items-center">Idioms</div>
    </div>
  </div>

  <div class="field my-4">
    <label for="difficulty" class="font-medium"
      >Difficulty <span class="text-gray-500">(optional)</span>
    </label>
    <Dropdown
      id="difficulty"
      class="w-full"
      :model-value="level"
      @update:modelValue="emits('update:level', $event)"
      :options="cambridgeLevels"
      option-label="name"
      placeholder="Select a Difficulty"
    />
  </div>

  <div class="field my-4">
    <Button class="w-full font-bold flex-grow-1 justify-content-center" @click="emits('submit')"
      >Generate
    </Button>
  </div>
</template>

<style scoped></style>
