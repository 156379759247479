<template>
  <h1>Uh oh, an error occured</h1>
  <p>
    The error has been reported to the developers. Go
    <RouterLink to="/">back to the home page</RouterLink>
    and try again later.
  </p>
  <img src="@/assets/robot-error.jpg" class="w-full" />
</template>

<style scoped></style>
