<script setup lang="ts">
import 'odometer/themes/odometer-theme-default.css'
import { onMounted, ref, watch } from 'vue'
// @ts-ignore
import Odometer from 'odometer'

const props = defineProps({
  value: Number
})
const odometer = ref<HTMLDivElement>()

onMounted(() => {
  new Odometer({
    el: odometer.value,
    value: props.value,
    format: '(,ddd).dd',
    theme: 'default'
  })
})

watch(
  () => props.value,
  () => {
    if (!odometer.value || !props.value) return
    odometer.value.innerHTML = props.value.toString()
  }
)
</script>

<template>
  <div ref="odometer"></div>
</template>

<style>
.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  font-family: inherit;
  font-feature-settings: inherit;
}
</style>
