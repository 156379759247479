<script setup lang="ts">
import { inject, nextTick, onMounted, ref } from 'vue'
import { httpsCallable, getFunctions, type HttpsCallableResult } from 'firebase/functions'
// @ts-ignore
import ResponseToast from '@/components/Quiz/ResponseToast.vue'
import type {
  InputType,
  CheckAnswerResponse,
  GPTResponseQuestion,
  GlobalLoaderFunc
} from '../../types/custom-types'
import {
  INPUT_TYPE_PROBABILITY,
  INPUT_TYPE_TIMEOUT_SECS,
  VOICE_ANSWER_SM2INTERVAL_THRESHOLD,
  TEXT_ANSWER_SM2INTERVAL_THRESHOLD
} from '@/config'
// @ts-ignore
import VoiceRecorder from '@/components/Quiz/VoiceRecorder.vue'
import { storeToRefs } from 'pinia'
import { useQuizSessionStore } from '@/stores/quizSession'
// @ts-ignore
import SessionProgress from '@/components/Quiz/SessionProgressBar.vue'
// @ts-ignore
import PlayVoice from '@/components/PlayVoice.vue'
// @ts-ignore
import CountdownTimer from '@/components/Quiz/CountdownTimer.vue'
import { useAuthStore } from '@/stores/auth'
// @ts-ignore
import SessionWelcome from '@/components/Quiz/SessionWelcome.vue'
// @ts-ignore
import QuestionHeader from '@/components/Quiz/QuestionHeader.vue'
// @ts-ignore
import SessionComplete from '@/components/Quiz/SessionComplete.vue'
import _ from 'lodash'
import { blobToBase64, calcSm2Rating } from '@/utils'
import InfoTooltip from '@/components/InfoTooltip.vue'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useSounds } from '@/composables/useSounds'
import { Icon } from '@iconify/vue'
import WordIllustration from "@/components/Home/WordIllustration.vue";
import {hideWord} from "@/services/wordService";

const toggleGlobalLoader = inject('toggleGlobalLoader') as GlobalLoaderFunc

const authStore = useAuthStore()

const textAnswer = ref('')
const inputType = ref<InputType>('multiplechoice')
const transcribedText = ref('')
const selectedAnswer = ref<number | null>(null)
const isSessionStarted = ref(false)
const isSubmitted = ref(false)
const isEvaluated = ref(false)
const isAnswerCorrect = ref<boolean | null>(null)
const isLoaded = ref(false)
const isSessionCompleted = ref(false)
const responseToast = ref<typeof ResponseToast>()
const voiceRecorder = ref<typeof VoiceRecorder>()
const countdownTimer = ref<typeof CountdownTimer>()
const timerDuration = ref(10)
const timerProgress = ref(100)
const timerStarted = ref(false)

let preloadedQuestion: GPTResponseQuestion | null = null
let preloadedPromise: Promise<HttpsCallableResult<unknown>> | null = null

const quizSessionStore = useQuizSessionStore()
const {
  questionSequence,
  questionIndex,
  correctCount,
  wrongCount,
  currentWord,
  currentQuestion,
  suppressAudioQuestions,
  isSilentMode
} = storeToRefs(quizSessionStore)

const functions = getFunctions()
const analytics = getAnalytics()

async function reportAnswer() {
  const reportAnswer = httpsCallable(functions, 'reportAnswer')
  const scoreResult = await reportAnswer({
    correct: isAnswerCorrect.value,
    qualityOfRecall: calcSm2Rating(timerProgress.value) ?? 1, // if no rating is given, we assume the answer was wrong
    wordId: currentWord.value.id
  })
}

async function submitMultipleChoice(index: number) {
  useSounds().playClick()
  isSubmitted.value = true
  selectedAnswer.value = index
  isEvaluated.value = false
  countdownTimer.value!.stop()

  const checkAnswer = httpsCallable(functions, 'checkAnswer')
  const response = (await checkAnswer({
    answer: currentQuestion.value?.options[index],
    correctAnswer: currentQuestion.value?.correctAnswer,
    id: currentWord.value.id
  })) as CheckAnswerResponse

  isEvaluated.value = true
  isAnswerCorrect.value = response.data.correct
  if (isAnswerCorrect.value) {
    useSounds().playCorrect()
  } else {
    useSounds().playWrong()
  }
  quizSessionStore.markAnswerAsCorrect(isAnswerCorrect.value)
  responseToast.value!.showToast(isAnswerCorrect.value)

  logEvent(analytics, 'quiz_answer_submit', { type: 'multiple_choice' })

  const reportAnswerResult = await reportAnswer()
}

async function submitText() {
  isSubmitted.value = true
  isEvaluated.value = false
  countdownTimer.value!.stop()

  const checkTextAnswer = httpsCallable(functions, 'checkTextAnswer')
  const response = (await checkTextAnswer({
    word: currentWord.value.word,
    answer: textAnswer.value,
    correctAnswer: currentQuestion.value?.correctAnswer,
    id: currentWord.value.id,
    evaluator: currentQuestion.value?.evaluator
  })) as CheckAnswerResponse

  isEvaluated.value = true
  isAnswerCorrect.value = response.data.correct
  quizSessionStore.markAnswerAsCorrect(isAnswerCorrect.value)
  responseToast.value!.showToast(isAnswerCorrect.value)

  logEvent(analytics, 'quiz_answer_submit', { type: 'text' })

  const reportAnswerResult = await reportAnswer()
}

async function voiceRecorded(data: { blob: Blob; mimeType: string }) {
  console.log('stopRecording', data)

  toggleGlobalLoader(true, false, 'Processing answer')
  isSubmitted.value = true
  inputType.value = 'voice'
  countdownTimer.value!.stop()

  const base64 = await blobToBase64(data.blob)
  const payload = {
    word: currentWord.value.word,
    mimeType: data.mimeType,
    data: base64,
    correctAnswer: currentQuestion.value?.correctAnswer,
    evaluator: currentQuestion.value?.evaluator,
    id: currentWord.value.id
  }

  try {
    const processVoiceAnswer = httpsCallable(functions, 'processVoiceAnswer')
    const response = (await processVoiceAnswer(payload)) as CheckAnswerResponse

    toggleGlobalLoader(false)
    isEvaluated.value = true
    isAnswerCorrect.value = response.data.correct
    transcribedText.value = response.data.transcribedText
    quizSessionStore.markAnswerAsCorrect(isAnswerCorrect.value)
    responseToast.value!.showToast(isAnswerCorrect.value)

    logEvent(analytics, 'quiz_answer_submit', { type: 'voice' })
    const reportAnswerResult = await reportAnswer()
  } catch (e) {
    // TODO: display an error message. This does not work for some reason.
    isSubmitted.value = false
    countdownTimer.value!.reset()
    toggleGlobalLoader(false)
  }
}

function timerExpired() {
  isSubmitted.value = true
  isEvaluated.value = true
  isAnswerCorrect.value = false
  quizSessionStore.markAnswerAsCorrect(isAnswerCorrect.value)
  responseToast.value!.showToast(isAnswerCorrect.value)

  logEvent(analytics, 'quiz_answer_timer_expired')
}

function onTimerProgress(progress: number) {
  timerProgress.value = progress
}

async function markAsCorrect() {
  isAnswerCorrect.value = true
  await reportAnswer()
}

/**
 * Reports the answer and the SM2 rating to the backend
 * @param rating
 */
async function gotoNextQuestion() {
  if (questionIndex.value === questionSequence.value.length) {
    // we are at the end of the session
    isSessionCompleted.value = true
    toggleGlobalLoader(false)

    // Give a second for the dbUpdate to complete
    window.setTimeout(() => {
      quizSessionStore.storeSessionCompleted(authStore.score)
    }, 1000)
  } else {
    await getNextQuestion()
  }

  toggleGlobalLoader(false)
}

function getAnswerType(isVoiceAnswerAllowed: boolean, isTextAnswerAllowed: boolean): InputType {
  const prob = Math.random()
  if (
    currentWord.value.sm2interval > VOICE_ANSWER_SM2INTERVAL_THRESHOLD &&
    prob < INPUT_TYPE_PROBABILITY.voice &&
    isVoiceAnswerAllowed &&
    !suppressAudioQuestions.value
  ) {
    return 'voice'
  } else if (
    currentWord.value.sm2interval > TEXT_ANSWER_SM2INTERVAL_THRESHOLD &&
    prob < INPUT_TYPE_PROBABILITY.text &&
    isTextAnswerAllowed
  ) {
    return 'text'
  } else {
    return 'multiplechoice'
  }
}

function skipQuestion() {
  gotoNextQuestion()
}

async function hideWordClick() {
  await hideWord(currentWord.value.id!, true)
  gotoNextQuestion()
}

async function getNextQuestion(isFirstQuestion = false) {
  if (questionIndex.value >= questionSequence.value.length) {
    // we are at the end of the session
    isSessionCompleted.value = true
    toggleGlobalLoader(false)
    return
  }

  toggleGlobalLoader(true, false, 'AI is generating question')

  isSubmitted.value = false
  isEvaluated.value = false
  transcribedText.value = ''
  textAnswer.value = ''

  await quizSessionStore.nextQuestion()
  isLoaded.value = true

  if (!currentQuestion) {
    return false
  }

  inputType.value = getAnswerType(
    currentQuestion.value!.voiceAnswerAllowed,
    currentQuestion.value!.textAnswerAllowed
  )

  // Work around an issue that "countdownTimer" is not yet available. Even if trying to get it
  // via nextTick() or onMounted() does not work.
  window.setTimeout(() => {
    countdownTimer.value!?.reset()
    switch (inputType.value) {
      case 'voice':
        timerDuration.value = INPUT_TYPE_TIMEOUT_SECS.voice
        break
      case 'text':
        timerDuration.value = INPUT_TYPE_TIMEOUT_SECS.text
        break
      case 'multiplechoice':
        timerDuration.value = INPUT_TYPE_TIMEOUT_SECS.multiplechoice
        break
    }
    countdownTimer.value!.start(timerDuration.value * currentQuestion.value!.difficultyFactor)
    preloadedQuestion = null
    toggleGlobalLoader(false)
  }, 1000)
}

function startRecording() {
  console.log('startRecording')
}

async function startSession() {
  // await quizSessionStore.resetSession()
  isSessionStarted.value = true
  isSessionCompleted.value = false
  //preloadNextQuestion(true)
  logEvent(analytics, 'quiz_start_session')
  await getNextQuestion(true)
}

async function prepareSession() {
  isSessionStarted.value = false
  // preloadNextQuestion(true)
}

onMounted(async () => {})
</script>
<template>
  <template v-if="!isSessionStarted">
    <SessionWelcome @startSession="startSession()" @questionSequenceLoaded="prepareSession()" />
  </template>
  <template v-else>
    <template v-if="isSessionCompleted">
      <SessionComplete
        :correctCount="correctCount"
        :wrongCount="wrongCount"
        :points-collected="authStore.score - quizSessionStore.startScore"
        @startSession="prepareSession()"
      />
    </template>
    <template v-else>
      <div v-if="isLoaded && currentQuestion && currentWord">
        <SessionProgress class="mt-4" />
        <template
          v-if="
            currentQuestion.questionType == 'voice' &&
            !suppressAudioQuestions &&
            currentWord.voiceFilename
          "
        >
          <div class="my-4">
            <PlayVoice
              v-if="currentWord.voiceFilename"
              :isFullPlayer="true"
              :filename="currentWord.voiceFilename"
            />
          </div>
        </template>
        <template v-else>
          <QuestionHeader :text="currentQuestion.question" />
        </template>

        <p :class="{ 'text-gray-500': isSubmitted }">
          {{ currentQuestion.instructions }}
          <InfoTooltip
            text="Click on the microphone icon if you want to provide a verbal answer."
          />
        </p>
        <p v-if="inputType === 'voice'" :class="{ 'text-gray-500': isSubmitted }">
          A verbal answer is expected. Press the microphone button to start and stop the recording.
        </p>

        <template v-if="inputType === 'text'">
          <InputText
            class="w-full"
            v-model="textAnswer"
            maxlength="100"
            :disabled="isSubmitted"
            :class="{
              'bg-green-800': isEvaluated && isAnswerCorrect,
              'bg-red-800': isEvaluated && !isAnswerCorrect,
              'text-white': isEvaluated,
              'text-center': isEvaluated
            }"
            @keyup.enter="submitText"
          />

          <Button
            v-if="isEvaluated && !isAnswerCorrect"
            :disabled="true"
            class="my-2 w-full block bg-green-800"
          >
            {{ currentQuestion.correctAnswer }}
          </Button>

          <Button
            v-if="!isSubmitted"
            :disabled="textAnswer.length < 5"
            @click="submitText"
            class="my-2 w-full block"
          >
            Submit
          </Button>
        </template>
        <template v-else>
          <Button
            v-if="inputType == 'voice' && isEvaluated"
            :disabled="true"
            class="my-2 w-full block"
            :class="{
              'bg-green-800': isAnswerCorrect,
              'bg-red-800': !isAnswerCorrect
            }"
          >
            «{{ transcribedText }}»
          </Button>
          <div
            v-for="(option, index) in currentQuestion.options"
            :key="index"
            @click="submitMultipleChoice(index)"
          >
            <Button
              v-if="
                inputType == 'multiplechoice' ||
                (isEvaluated &&
                  currentQuestion.correctAnswer.toLowerCase() === option.toLowerCase())
              "
              :disabled="isSubmitted"
              class="my-2 w-full block"
              :class="{
                'bg-green-800':
                  isEvaluated &&
                  currentQuestion.correctAnswer.toLowerCase() === option.toLowerCase(),
                'bg-red-800':
                  selectedAnswer &&
                  isEvaluated &&
                  currentQuestion.options[selectedAnswer] === option &&
                  !isAnswerCorrect
              }"
            >
              {{ option }}
            </Button>
          </div>
        </template>

        <Button
          v-if="isEvaluated && !isAnswerCorrect"
          link
          @click="markAsCorrect"
          class="my-2 w-full block"
          >It was correct
        </Button>

        <div class="flex flex-row">
        <Button v-if="!isEvaluated" link @click="hideWordClick" class="my-2 w-full block"> Stop asking for this word
        </Button>
        <Button v-if="!isEvaluated" link @click="skipQuestion" class="my-2 w-full block"
          >Skip this question
        </Button>
          </div>

        <CountdownTimer
          ref="countdownTimer"
          class="my-4"
          :seconds="timerDuration"
          :started="timerStarted"
          @progress="onTimerProgress"
          @expired="timerExpired"
        ></CountdownTimer>

        <div v-if="isEvaluated" class="my-4">
          {{ currentWord.translation }}
          <Icon
            v-if="currentWord.rarity > 3"
            v-tooltip="'rare word'"
            class="mx-1"
            style="vertical-align: text-top"
            icon="system-uicons:diamond"
            title="rare word"
            height="20"
          />
          — <span>{{ currentWord.explanation }}</span>
        </div>

        <div v-if="isSubmitted && isEvaluated">
          <Button @click="gotoNextQuestion" class="my-3 w-full block" v-focus> Next</Button>
        </div>

        <ResponseToast ref="responseToast" />
        <VoiceRecorder
          ref="voiceRecorder"
          v-if="!isEvaluated"
          :disabled="isSubmitted"
          @start="startRecording"
          @stop="voiceRecorded"
          class="mt-4"
        />

        <WordIllustration class="my-4" v-if="isEvaluated && currentWord.imageFilename" :wordData="currentWord" />

        <div class="text-gray-500 text-xs mt-8">
          SM2Ease: {{ currentWord.sm2ease.toFixed(1) }}. SM2Interval:
          {{ Math.floor(currentWord.sm2interval) }}.
          <br />
          Last answer: {{ currentWord.lastAnswer?.date }}.
        </div>
      </div>
    </template>
  </template>
</template>
