<script setup lang="ts">
import { marked } from 'marked';

const props = defineProps({
    text: {
      type: String,
      required: true,
    }
})

function html() {
  return marked.parse(props.text)
}

</script>

<template>
  <h1 v-html="html()" :style="text.length > 20 ? 'font-size: 1.5em' :''"></h1>
</template>


