import './assets/main.css'
//theme
import 'primevue/resources/themes/lara-light-indigo/theme.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'

import { createApp, onBeforeMount, onUnmounted } from 'vue'
import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
// @ts-ignore
import GlobalLoader from '@/components/GlobalLoader.vue'

// @ts-ignore
import App from '@/App.vue'
import router from './router'
import Dialog from 'primevue/dialog'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(PrimeVue)
app.use(ToastService)
// eslint-disable-next-line vue/multi-word-component-names,vue/no-reserved-component-names
app.component('Button', Button)
// eslint-disable-next-line vue/multi-word-component-names,vue/no-reserved-component-names
app.component('InputText', InputText)
// eslint-disable-next-line vue/multi-word-component-names,vue/no-reserved-component-names
app.component('Dialog', Dialog)
// eslint-disable-next-line vue/multi-word-component-names,vue/no-reserved-component-names
app.component('Textarea', Textarea)
// eslint-disable-next-line vue/multi-word-component-names,vue/no-reserved-component-names
app.component('Toast', Toast)

app.directive('focus', {
  // When the bound element is mounted into the DOM...
  mounted(el) {
    // Focus the element
    el.focus()
  }
})

app.directive('tooltip', Tooltip)
/*app.config.errorHandler = (err) => {
  try {
    const toast = useToast()
    toast.add({
      severity: 'error',
      summary: 'An error occurred. Please try again.',
      detail: err || 'Unknown error',
      life: 5000
    })
  } catch (e) {
    // This will certainly work, even if the component is not rendered
    location.href = '/error'
  }
}*/

const loaderContainer = document.createElement('div')
document.body.appendChild(loaderContainer)
const loaderInstance = createApp(GlobalLoader).mount(loaderContainer)
// @ts-ignore
app.provide('toggleGlobalLoader', loaderInstance.toggle)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.DEV ? 'dev' : 'prod',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'https://vocabs.ai',
        'https://ai-vocabs-c98f4.web.app/',
        'https://vocabs.mpom.ch/'
      ],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.mount('#app')
