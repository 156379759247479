<script setup lang="ts">
import { useToast } from 'primevue/usetoast'

const feedbackMessages = {
  compliments: [
    'Nailed it!',
    "You're on fire!",
    'Look at you go!',
    "You're a natural!",
    'Smashed it out of the park!',
    "You're unstoppable!",
    'That was spot on!',
    "You're really getting the hang of this!",
    'Amazing job!',
    "You're crushing it!",
    'A+ work right there!',
    "You're a quick learner!",
    'Keep up the great work!',
    "You've got this down!",
    "You're a pro!",
    'Fantastic answer!',
    'Genius move!',
    'That was first-class!',
    "You're acing this!",
    "Wow, you're good at this!"
  ],
  encouragements: [
    "Don't sweat it!",
    "No worries, you'll get the next one!",
    "You're still in the game!",
    "Keep going, you've got this!",
    'You were close!',
    "It's just a warm-up, you'll catch up!",
    'Almost there!',
    "You're learning, that's what matters!",
    'Hey, even pros make mistakes!',
    "It's all part of the journey!",
    "Don't give up!",
    "You'll nail the next one!",
    "You're doing better than you think!",
    "That was a tough one, don't worry!",
    "You'll get it next time!",
    "Keep pushing, you're doing great!",
    "Mistakes happen, you'll bounce back!",
    "Don't be too hard on yourself!",
    "You're still on track!",
    "It's all a learning experience!"
  ]
}

const toast = useToast()

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: 'Correct',
    detail:
      feedbackMessages.compliments[Math.floor(Math.random() * feedbackMessages.compliments.length)],
    life: 3000
  })
}

const showError = () => {
  toast.add({
    severity: 'error',
    summary: 'Wrong',
    detail:
      feedbackMessages.encouragements[
        Math.floor(Math.random() * feedbackMessages.compliments.length)
      ],
    life: 3000
  })
}

const showToast = (isSuccess: boolean) => {
  if (isSuccess) {
    showSuccess()
  } else {
    showError()
  }
}

defineExpose({
  showSuccess,
  showError,
  showToast
})
</script>
<template>
  <div></div>
</template>
