import { createRouter, createWebHistory, type RouteLocationNormalized } from 'vue-router'
// @ts-ignore
import WordManagement from '@/views/WordManagement.vue'
// @ts-ignore
import SuggestWordsView from '@/views/SuggestWordsView.vue'
// @ts-ignore
import QuizQuestion from '@/views/QuizQuestion.vue'
// @ts-ignore
import AboutView from '@/views/AboutView.vue'
// @ts-ignore
import HomeView from '@/views/HomeView.vue'
// @ts-ignore
import ChartView from '@/views/ChartView.vue'
// @ts-ignore
import DebugView from '@/views/DebugView.vue'
import { useAuthStore } from '@/stores/auth'
// @ts-ignore
import ErrorView from '@/views/ErrorView.vue'
// @ts-ignore
import PrivacyView from "@/views/PrivacyView.vue";
// @ts-ignore
import SettingsView from "@/views/SettingsView.vue";
import { getAnalytics, logEvent } from 'firebase/analytics'
// @ts-ignore
import ImportView from "@/views/ImportView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/words',
      name: 'words',
      component: WordManagement,
      meta: { public: false }
    },
    {
      path: '/suggest-words',
      name: 'suggest-words',
      component: SuggestWordsView,
      meta: { public: false }
    },
    {
      path: '/import',
      name: 'import',
      component: ImportView,
      meta: { public: false }
    },
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: { public: true }
    },
    {
      path: '/practice',
      name: 'practice',
      component: QuizQuestion,
      meta: { public: false }
    },
    {
      path: '/about',
      name: 'about',
      component: AboutView,
      meta: { public: true }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: PrivacyView,
      meta: { public: true }
    },
    {
      path: '/debug',
      name: 'debug',
      component: DebugView,
      meta: { public: true }
    },
    {
      path: '/chart',
      name: 'chart',
      component: ChartView,
      meta: { public: false }
    },
    {
      path: '/settings',
      name: 'settings',
      component: SettingsView,
      meta: { public: false }
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorView,
      meta: { public: true }
    }
  ]
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) => {
  const auth = useAuthStore()

  // Your condition logic here
  const userIsAuthenticated = auth.user

  const analytics = getAnalytics()
  logEvent(analytics, 'screen_view', {
    firebase_screen: to.name,
    firebase_screen_class: 'generic'
  })

  if (userIsAuthenticated || to.meta.public) {
    next() // proceed to the route
  } else {
    // Redirect to target URL if the user isn't authenticated
    next('/')
  }
})

export default router
