<script setup lang="ts">
import Card from 'primevue/card'
import {computed, onMounted} from 'vue'
import sadRobot from '@/assets/robot-sad.jpg'
import blandRobot from '@/assets/robot-bland.jpg'
import thumbsUpRobot from '@/assets/robot-thumbs-up.jpg'
import {getAnalytics, logEvent} from "firebase/analytics";
import {useSounds} from "@/composables/useSounds";

const props = defineProps({
  correctCount: {
    type: Number,
    required: true
  },
  wrongCount: {
    type: Number,
    required: true
  },
  pointsCollected: {
    type: Number,
    required: true
  }
})

const robotImageUrl = () => {
  switch (true) {
    case completionRate.value < 50:
      return sadRobot
    case completionRate.value < 80:
      return blandRobot
    default:
      return thumbsUpRobot
  }
}

const titleText = computed(() => {
  switch (true) {
    case completionRate.value < 50:
      return 'You can do better.'
    case completionRate.value < 80:
      return 'Not bad.'
    default:
      return 'Great job!'
  }
})

const completionRate = computed(() => {
  if ((props.correctCount + props.wrongCount) === 0) return 0
  return (props.correctCount / (props.correctCount + props.wrongCount)) * 100
})

const emits = defineEmits(['startSession'])

onMounted(() => {
  const analytics = getAnalytics()
  logEvent(analytics, 'quiz_session_complete', {
    'completion_rate': completionRate
  })
  if (completionRate.value >= 80) {
    useSounds().playWin()
  } else {
    useSounds().playLose()
  }
})
</script>
<template>
  <h1>Practice Completed! {{titleText}}</h1>

  <div class="grid grid-nogutter my-2 w-full">
    <Card class="col-6">
      <template #title>
        <span class="text-4xl">{{ completionRate.toFixed(0) }}%</span>
      </template>
      <template #content> Correct Answers</template>
    </Card>
    <Card class="col-6">
      <template #title>
        <span class="text-4xl">{{ pointsCollected > 0 ? '+' : '' }}{{ pointsCollected }}</span>
      </template>
      <template #content> Points Collected
        <br><RouterLink to="/chart">See charts</RouterLink>
      </template>
    </Card>
  </div>

  <Button class="w-full flex-grow-1 justify-content-center my-2" @click="emits('startSession')"
    >Start new practice
  </Button>

  <img :src="robotImageUrl()" class="w-full my-4" />
</template>
