import type { Speaker } from '../types/custom-types'

export const INPUT_TYPE_PROBABILITY = {
  multiplechoice: 0.1,
  text: 0.8,
  voice: 0.8
}

export const INPUT_TYPE_TIMEOUT_SECS = {
  multiplechoice: 20,
  text: 60,
  voice: 20
}

//export const GPT_MODEL : string = 'gpt-3.5-turbo'
//export const GPT_MODEL: string = 'gpt-4'
export const GPT_MODEL: string = 'gpt-4o'

export const INTERVAL_TO_DAY = 1 / 24 / 4 // 15 minutes

// Should we pregenerate questions on the server?
export const PREGENERATION_ENABLED = true

export const MAX_WORDS_PER_SESSION = 10

// If a word is below this threshold, text or voice answers will not be allowed
export const VOICE_ANSWER_SM2INTERVAL_THRESHOLD = 30
export const TEXT_ANSWER_SM2INTERVAL_THRESHOLD = 30

export const LANGUAGES: { label: string; value: string; placeholderTranslation: string }[] = [
  {
    value: 'de',
    label: 'German',
    placeholderTranslation: 'fortgeschritten, gemütlich, schlafende Hunde beissen nicht'
  },
  {
    value: 'fil',
    label: 'Filipino',
    placeholderTranslation: 'advanced, maaliwalas, natutulog na aso ay hindi kumagat'
  },
  {
    value: 'fr',
    label: 'French',
    placeholderTranslation: 'avancé, confortable, il ne faut pas réveiller le chat qui dort'
  },
  {
    value: 'it',
    label: 'Italian',
    placeholderTranslation: 'avanzato, accogliente, non svegliare il can che dorme'
  },
  {
    value: 'pl',
    label: 'Polish',
    placeholderTranslation: 'zaawansowany, przytulny, nie wywołuj wilka z lasu'
  },
  {
    value: 'ru',
    label: 'Russian',
    placeholderTranslation: 'продвинутый, уютный, спячими собаками не будят'
  }
]

export const SPEAKERS: Record<string, Speaker> = {
  pNInz6obpgDQGcFmaJgB: {
    name: 'Adam',
    description: 'American male',
    preview_url:
      'https://storage.googleapis.com/eleven-public-prod/premade/voices/pNInz6obpgDQGcFmaJgB/38a69695-2ca9-4b9e-b9ec-f07ced494a58.mp3'
  },
  onwK4e9ZLuTAKqWW03F9: {
    name: 'Daniel',
    description: 'British male',
    preview_url:
      'https://storage.googleapis.com/eleven-public-prod/premade/voices/onwK4e9ZLuTAKqWW03F9/7eee0236-1a72-4b86-b303-5dcadc007ba9.mp3'
  },
  ThT5KcBeYPX3keUQqHPh: {
    name: 'Dorothy',
    description: 'British female',
    preview_url:
      'https://storage.googleapis.com/eleven-public-prod/premade/voices/ThT5KcBeYPX3keUQqHPh/981f0855-6598-48d2-9f8f-b6d92fbbe3fc.mp3'
  },
  D38z5RcWu1voky8WS1ja: {
    name: 'Fin',
    description: 'Irish male',
    preview_url:
      'https://storage.googleapis.com/eleven-public-prod/premade/voices/D38z5RcWu1voky8WS1ja/a470ba64-1e72-46d9-ba9d-030c4155e2d2.mp3'
  },
  ZQe5CZNOzWyzPSCn5a3c: {
    name: 'James',
    description: 'Australian male',
    preview_url:
      'https://storage.googleapis.com/eleven-public-prod/premade/voices/ZQe5CZNOzWyzPSCn5a3c/35734112-7b72-48df-bc2f-64d5ab2f791b.mp3'
  },
  XrExE9yKIg1WjnnlVkGX: {
    name: 'Matilda',
    description: 'American female',
    preview_url:
      'https://storage.googleapis.com/eleven-public-prod/premade/voices/XrExE9yKIg1WjnnlVkGX/b930e18d-6b4d-466e-bab2-0ae97c6d8535.mp3'
  },
  '29WjlYYnFuOqt8D95aVF': {
    name: 'William',
    description: 'American male',
    preview_url: 'https://storage.googleapis.com/eleven-public-prod/TGwM86H7VUV1Ih5PGMZswTkL5Qd2/voices/6buUoCSgjTtLCEqFXwEc/366919c8-f824-4990-88f1-fdfe58461ca1.mp3'
  }
}
