<script setup lang="ts">
import { inject, onMounted, ref } from 'vue'
import { getFunctions, httpsCallable } from 'firebase/functions'
import type { DatabaseWord, GlobalLoaderFunc } from '../../types/custom-types'
import PreferenceForm from '@/components/SuggestWords/PreferenceForm.vue'
import SelectItems from '@/components/SuggestWords/SelectItems.vue'
import { auth, db } from '@/firebase/init'
import { addDoc, collection, doc } from 'firebase/firestore'
import { useAuthStore } from '@/stores/auth'
import { createWord } from '@/services/wordService'

const toggleGlobalLoader = inject('toggleGlobalLoader') as GlobalLoaderFunc
const functions = getFunctions()

const state = ref<'userpref' | 'wordselection' | 'confirmation'>('userpref')
let words = ref([] as string[])

const domain = ref('')
const wantIdioms = ref(false)
const level = ref<{ name: string; code: string } | undefined>()
const wordCount = ref(0)

async function generateWords() {
  toggleGlobalLoader(true, false, 'Generating items')
  const generateWords = httpsCallable(functions, 'generateWords')
  const result = await generateWords({
    domain: domain.value,
    wantIdioms: wantIdioms.value,
    level: level.value?.name
  })
  words.value = result.data as string[]
  state.value = 'wordselection'
  toggleGlobalLoader(false, false)
}

async function addItems(items: string[]) {
  const auth = useAuthStore()
  wordCount.value = items.length
  const p = items.map(async (item) => {
    const data: DatabaseWord = {
      word: item,
      translation: '',
      explanation: '',
      samplePhrase: '',
      userRef: doc(db, 'users/' + auth.user!.uid),
      correct: 0,
      wrong: 0,
      sm2ease: 0,
      sm2interval: 0,
      domain: domain.value,
    }
    await createWord(data)
  })
  await Promise.all(p)
  state.value = 'confirmation'
}

onMounted(async () => {})
</script>
<template>
  <div>
    <h1>Suggest Words</h1>
    <template v-if="state == 'userpref'">
      <PreferenceForm
        @submit="generateWords"
        v-model:domain="domain"
        v-model:wantIdioms="wantIdioms"
        v-model:level="level"
      />
    </template>
    <template v-if="state == 'wordselection'">
      <SelectItems :items="words" @try-again="state = 'userpref'" @addItems="addItems" />
    </template>
    <template v-if="state == 'confirmation'">
      <p>The {{ wordCount }} items have been added to your vocabulary.</p>
      <p>
        The explanation, translation and sample phrase will be generated automatically after a
        couple of minutes.
      </p>
      <Button
        class="w-full my-2 flex-grow-1 justify-content-center"
        icon="pi pi-pencil"
        @click="() => $router.push('/words')"
        >{{ words.length > 0 ? 'Manage Vocab' : 'Add Vocab Item' }}
      </Button>
    </template>
  </div>
</template>

<style scoped></style>
