import { Howl } from 'howler'

import correctSound from '@/assets/sounds/correct.wav'
import wrongSound from '@/assets/sounds/wrong.wav'
import winSound from '@/assets/sounds/win.wav'
import loseSound from '@/assets/sounds/lose.wav'
import clickSound from '@/assets/sounds/click.wav'

export const useSounds = () => {
  const correctSoundInstance = new Howl({
    src: [correctSound],
    volume: 0.5
  })

  const wrongSoundInstance = new Howl({
    src: [wrongSound],
    volume: 0.5
  })

  const winSoundInstance = new Howl({
    src: [winSound],
    volume: 0.5
  })

  const loseSoundInstance = new Howl({
    src: [loseSound],
    volume: 0.5
  })

  const clickSoundInstance = new Howl({
    src: [clickSound],
    volume: 0.5
  })

  const playCorrect = () => correctSoundInstance.play()
  const playWrong = () => wrongSoundInstance.play()
  const playWin = () => winSoundInstance.play()
  const playLose = () => loseSoundInstance.play()
  const playClick = () => clickSoundInstance.play()

  return {
    playCorrect,
    playWrong,
    playWin,
    playLose,
    playClick
  }
}
