<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="120"
    viewBox="12 0 40 40"
    xml:space="preserve"
    style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
  >
    <rect
      id="ray_0"
      x="30.75"
      y="12.0"
      width="2.5"
      height="20"
      style="fill: rgb(100, 102, 241)"
      transform="rotate(270.0,32.0,32.0)"
    />
    <rect
      id="ray_1"
      x="30.75"
      y="12.0"
      width="2.5"
      height="20"
      style="fill: rgb(100, 102, 241)"
      transform="rotate(300.0,32.0,32.0)"
    />
    <rect
      id="ray_2"
      x="30.75"
      y="12.0"
      width="2.5"
      height="20"
      style="fill: rgb(100, 102, 241)"
      transform="rotate(330.0,32.0,32.0)"
    />
    <rect
      id="ray_3"
      x="30.75"
      y="12.0"
      width="2.5"
      height="20"
      style="fill: rgb(100, 102, 241)"
      transform="rotate(360.0,32.0,32.0)"
    />
    <rect
      id="ray_4"
      x="30.75"
      y="12.0"
      width="2.5"
      height="20"
      style="fill: rgb(100, 102, 241)"
      transform="rotate(390.0,32.0,32.0)"
    />
    <rect
      id="ray_5"
      x="30.75"
      y="12.0"
      width="2.5"
      height="20"
      style="fill: rgb(100, 102, 241)"
      transform="rotate(420.0,32.0,32.0)"
    />
    <rect
      id="ray_6"
      x="30.75"
      y="12.0"
      width="2.5"
      height="20"
      style="fill: rgb(100, 102, 241)"
      transform="rotate(450.0,32.0,32.0)"
    />
  </svg>
</template>
<style scoped>

#ray_0 {
  animation: ray0 5s infinite;
  animation-delay: 0s;
}

#ray_1 {
  animation: ray1 5s infinite;
  animation-delay: 0s;
}

#ray_2 {
  animation: ray2 5s infinite;
  animation-delay: 0s;
}

#ray_3 {
  animation: ray3 5s infinite;
  animation-delay: 0s;
}

#ray_4 {
  animation: ray4 5s infinite;
  animation-delay: 0s;
}

#ray_5 {
  animation: ray5 5s infinite;
  animation-delay: 0s;
}

@keyframes ray0 {
  0% {
    transform: rotate(270deg);
    transform-origin: 32px 32px;
  }
  50% {
    transform: rotate(450deg);
    transform-origin: 32px 32px;
  }
  100% {
    transform: rotate(270deg);
    transform-origin: 32px 32px;
  }
}

@keyframes ray1 {
  0% {
    transform: rotate(300deg);
    transform-origin: 32px 32px;
  }
  50% {
    transform: rotate(450deg);
    transform-origin: 32px 32px;
  }
  100% {
    transform: rotate(300deg);
    transform-origin: 32px 32px;
  }
}
@keyframes ray2 {
  0% {
    transform: rotate(330deg);
    transform-origin: 32px 32px;
  }
  50% {
    transform: rotate(450deg);
    transform-origin: 32px 32px;
  }
  100% {
    transform: rotate(330deg);
    transform-origin: 32px 32px;
  }
}
@keyframes ray3 {
  0% {
    transform: rotate(360deg);
    transform-origin: 32px 32px;
  }
  50% {
    transform: rotate(450deg);
    transform-origin: 32px 32px;
  }
  100% {
    transform: rotate(360deg);
    transform-origin: 32px 32px;
  }
}
@keyframes ray4 {
  0% {
    transform: rotate(390deg);
    transform-origin: 32px 32px;
  }
  50% {
    transform: rotate(450deg);
    transform-origin: 32px 32px;
  }
  100% {
    transform: rotate(390deg);
    transform-origin: 32px 32px;
  }
}
@keyframes ray5 {
  0% {
    transform: rotate(420deg);
    transform-origin: 32px 32px;
  }
  50% {
    transform: rotate(450deg);
    transform-origin: 32px 32px;
  }
  100% {
    transform: rotate(420deg);
    transform-origin: 32px 32px;
  }
}
</style>
