<script setup lang="ts">
import { useQuizSessionStore } from '@/stores/quizSession'
import { storeToRefs } from 'pinia'

const quizSessionStore = useQuizSessionStore()
const { questionSequence, questionIndex, correctCount, wrongCount } =
  storeToRefs(quizSessionStore)
</script>

<template>
  <div>
    <div class="flex flex-row w-full progressbar">
      <div
        v-for="word in questionSequence"
        :key="word.id"
        class="flex-grow-1 increment"
        :class="{
          'is-first': word === questionSequence[0],
          'is-last': word === questionSequence[questionSequence.length - 1],
          'is-active': word === questionSequence[questionIndex - 1],
          'is-correct': word.correct === true,
          'is-wrong': word.correct === false
        }"
      ></div>
    </div>
  </div>
</template>

<style scoped>
.progressbar {
  background-color: #ccc
}

.increment {
  height: 6px;
  margin-right: 2px;
  opacity: 1;
}

.is-first {
  border-radius: 6px 0 0 6px;
}

.is-last {
  border-radius: 0 6px 6px 0;
}

.is-active {
  background-color: var(--primary-color);
}

.is-correct {
  background-color: var(--green-800);
}

.is-wrong {
  background-color: var(--red-800);
}
</style>
