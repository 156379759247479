<script setup lang="ts">
import { ref } from 'vue'
import AnimatedLoader from "@/components/AnimatedLoader.vue";

const show = ref(false)
const hideBackground = ref(false)
const message = ref('')

const toggle = (value: boolean, hideBg:boolean = false, msg='') => {
  show.value = value
  hideBackground.value = hideBg
  message.value = msg
}

defineExpose({
  toggle
})
</script>
<template>
  <div v-if="show" class="global-loader" :class="{ 'hide-bg': hideBackground }" >
    <AnimatedLoader />
    <div class="text-center absolute text-gray-500" style="padding-top: 130px">{{ message }}</div>
  </div>
</template>
<style scoped>
.global-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.global-loader.hide-bg {
  background-color: rgba(255, 255, 255, 1);
}
</style>
