<script setup lang="ts">
import {computed, onMounted, ref, watch} from 'vue'
import Checkbox from 'primevue/checkbox'
import {logEvent} from "firebase/analytics";
import {analytics} from "@/firebase/init";

const props = defineProps({
  items: {
    type: Array as () => string[],
    required: true
  }
})

const selectedWords = ref([] as string[])
const selectAll = ref([])

const sortedItems = computed(() => {
  const items = [...props.items]
  return items.sort((a, b) => a.localeCompare(b))
})

const emits = defineEmits(['addItems', 'tryAgain'])

watch(selectAll, (value) => {
  if (value.length) {
    selectedWords.value = sortedItems.value
  } else {
    selectedWords.value = []
  }
})

function addItems() {
  logEvent(analytics, 'word_suggested')
  emits('addItems', selectedWords.value)
}

onMounted(() => {
})
</script>

<template>
  <p >Which words would you like to add?</p>

  <div class="flex align-items-center my-2" >
    <Checkbox inputId="all" name="selectAll" v-model="selectAll" :value="true"/><label for="all" class="ml-2 text-gray-500">Select all</label>
  </div>


  <div class="flex align-items-center my-2" v-for="word in sortedItems" :key="word">
    <Checkbox v-model="selectedWords" :inputId="word" :value="word" />
    <label :for="word" class="ml-2"> {{ word }} </label>
  </div>

  <div class="flex flex-row mt-4">
    <Button @click="$emit('tryAgain')" icon="pi pi-replay" class="mr-2" label="Try again" />
    <Button @click="addItems()" icon="pi pi-check" label="Add selected items" />
  </div>
</template>

<style scoped></style>
