<script setup lang="ts">
import { ref, onMounted } from 'vue'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import { SPEAKERS } from '@/config'
import { useAuthStore } from '@/stores/auth'
import type { Speaker } from '../../types/custom-types'

const authStore = useAuthStore()

const emits = defineEmits(['update:modelValue'])

const modelValue = ref()
const speakers = Object.keys(SPEAKERS).map((s: string) => {
  return {
    value: s,
    label: SPEAKERS[s].name + ', ' + SPEAKERS[s].description,
    preview_url: SPEAKERS[s].preview_url
  }
})

async function play() {
  if (modelValue.value.preview_url === undefined) return
  const audio = new Audio(modelValue.value.preview_url)
  await audio.play()
}

onMounted(() => {
  // Set initial speaker if available
  if (authStore.dbUser?.speaker) {
    modelValue.value = speakers.find((s) => s.value === authStore.dbUser!.speaker)!
  } else {
    modelValue.value = speakers[0]
  }

  emits('update:modelValue', modelValue.value)
})

const updateSpeaker = (newSpeaker: Speaker) => {
  modelValue.value = newSpeaker
  emits('update:modelValue', newSpeaker)
}
</script>
<template>
  <div>
    <Dropdown
      v-model="modelValue"
      :options="speakers"
      optionLabel="label"
      placeholder="Select a Speaker"
      class="mr-2"
      @update:modelValue="updateSpeaker"
    />
    <Button class="mt-2" label="preview" icon="pi pi-volume-up" @click="play" outlined />
  </div>
</template>
