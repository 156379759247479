<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { computed, inject, onMounted, ref, watch } from 'vue'
import InputSwitch from 'primevue/inputswitch'
import type { SessionStoreWord, GlobalLoaderFunc } from '../../../types/custom-types'
import { useQuizSessionStore } from '@/stores/quizSession'
// @ts-ignore
import WordProgressBar from '@/components/WordProgressBar.vue'
import { useAuthStore } from '@/stores/auth'
import InfoTooltip from '@/components/InfoTooltip.vue'

const quizSessionStore = useQuizSessionStore()
const authStore = useAuthStore()
const { suppressAudioQuestions, skipTypingQuestions, startScore } = storeToRefs(quizSessionStore)
const isLoaded = ref(false)
const toggleGlobalLoader = inject('toggleGlobalLoader') as GlobalLoaderFunc

const { questionSequence, isSilentMode } = storeToRefs(quizSessionStore)
const auth = useAuthStore()

const emits = defineEmits(['startSession', 'questionSequenceLoaded'])

async function forceStartSession() {
  const functions = getFunctions()
  const getQuestionSequence = httpsCallable(functions, 'getQuestionSequence')
  const result = await getQuestionSequence({ ignoreInterval: true })
  questionSequence.value = result.data as SessionStoreWord[]
  quizSessionStore.isForceMode = true
  if (questionSequence.value.length > 0) {
    await quizSessionStore.startSession(questionSequence.value, authStore.score)
    emits('questionSequenceLoaded')
  }
}

const shuffledWords = computed(() => {
  if (!questionSequence.value) return []

  const wordCopy = [...questionSequence.value]
  return wordCopy.sort((a, b) => a.sm2interval - b.sm2interval)
})

onMounted(async () => {
  const functions = getFunctions()
  toggleGlobalLoader(true, true, 'Tailoring your practice')
  const getQuestionSequence = httpsCallable(functions, 'getQuestionSequence')
  const result = await getQuestionSequence({ ignoreInterval: false })
  isLoaded.value = true
  isSilentMode.value = auth.dbUser!.silentMode
  questionSequence.value = (result.data as SessionStoreWord[]).filter(
    (word) => word.nextDue < new Date().toISOString()
  )
  quizSessionStore.isForceMode = false
  if (questionSequence.value.length > 0) {
    await quizSessionStore.startSession(questionSequence.value, authStore.score)
    emits('questionSequenceLoaded')
  }

  suppressAudioQuestions.value = auth.dbUser!.silentMode

  toggleGlobalLoader(false, false)
})

watch(suppressAudioQuestions, (newValue) => {
  authStore.setSetting('silentMode', newValue ?? false)
  isSilentMode.value = newValue
})
</script>

<template>
  <div v-if="isLoaded">
    <template v-if="questionSequence.length > 0">
      <h1>Start your Practice</h1>
      <p>
        The SuperMemo2-algorithm has identified the following words which need the most practice:
      </p>
      <table class="my-4">
        <tr v-for="(question, index) in shuffledWords" v-bind:key="index">
          <td class="font-bold py-2">
            {{ question.word }}
          </td>
          <td class="px-2 text-right w-4rem align-items-center vertical-align-middle">
            <WordProgressBar :value="question.sm2interval" />
          </td>
        </tr>
      </table>
      <table class="align-items-center mb-2">
        <tr>
          <td class="vertical-align-middle">
            <label for="suppressAudio" class="mr-3"
              >Silent mode
              <InfoTooltip
                text="Disable the questions that necessitate verbal responses. This is useful in public environments"
              />
            </label>
          </td>
          <td class="vertical-align-middle">
            <InputSwitch name="suppressAudio" v-model="suppressAudioQuestions" />
          </td>
        </tr>
        <tr v-if="false">
          <td class="vertical-align-middle">
            <label for="skipTyping" class="mr-3">Skip Questions requiring typing </label>
          </td>
          <td class="vertical-align-middle">
            <InputSwitch name="skipTyping" v-model="skipTypingQuestions" />
          </td>
        </tr>
      </table>
      <Button class="w-full flex-grow-1 justify-content-center" @click="emits('startSession')"
        >Start Practice
      </Button>
    </template>
    <template v-else>
      <h1>There's no word ready to learn.</h1>
      <p>
        If you repeat the words too quickly, they will not enter long-term memory. So, try again in
        one hour or add some words.
      </p>
      <img src="../../assets/robot-shrugging.jpg" class="w-full" />

      <Button class="w-full flex-grow-1 justify-content-center" @click="forceStartSession()"
        >Start Practice anyway
      </Button>
    </template>
  </div>
</template>

<style scoped>
th {
  text-align: left;
}

tr,
td,
th {
  padding: 0.2rem 0.2rem 0.2rem 0;
}
</style>
