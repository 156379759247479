import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { type Ref } from 'vue'
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  type Unsubscribe,
  type User
} from 'firebase/auth'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where
} from 'firebase/firestore'
import { db } from '@/firebase/init'
import type { DatabaseWord, DatabaseUser } from '../../types/custom-types'
import { getAnalytics, type Analytics, setUserId } from 'firebase/analytics'

export const useAuthStore = defineStore('auth', () => {
  const score = ref(0)
  const isLoading = ref(false)
  const hasFailed = ref(false)
  const localError: Ref<unknown> = ref(null)
  const user: Ref<User | null> = ref(null)
  const dbUser: Ref<DatabaseUser | null> = ref(null)
  const hideLearnedWords = ref(false)
  let unwatchAuthState: Unsubscribe = () => {}
  let unsubscribeUserUpdates: Unsubscribe = () => {}

  const auth = getAuth()

  const watchAuthState = () => {
    console.log('watchAuthState')
    unwatchAuthState()
    unwatchAuthState = onAuthStateChanged(auth, async (_user) => {
      unsubscribeUserUpdates()
      console.log('onAuthStateChanged', _user)
      if (_user) {
        user.value = _user
        const analytics = getAnalytics()
        setUserId(analytics, _user.uid)
        subscribeToUserUpdates()
      } else {
        user.value = null
      }
    })
  }

  const loginInWithGoogle = async (): Promise<void> => {
    const provider = new GoogleAuthProvider()

    isLoading.value = true
    hasFailed.value = false
    localError.value = null

    try {
      const result = await signInWithPopup(auth, provider)
      user.value = result!.user
      subscribeToUserUpdates()
    } catch (error) {
      hasFailed.value = true
      localError.value = error
    } finally {
      isLoading.value = false
    }
  }

  const loginWithApple = async (): Promise<void> => {
    const provider = new OAuthProvider('apple.com')

    isLoading.value = true
    hasFailed.value = false
    localError.value = null

    try {
      const result = await signInWithPopup(auth, provider)
      user.value = result!.user
      subscribeToUserUpdates()
    } catch (error) {
      hasFailed.value = true
      localError.value = error
    } finally {
      isLoading.value = false
    }
  }

  const logout = async () => {
    await signOut(auth)
    user.value = null
    dbUser.value = null
    unwatchAuthState()
  }

  function subscribeToUserUpdates() {
    console.log('subscribeToUserUpdates')
    if (user.value === null) {
      return
    } else {
      const userDocRef = doc(db, 'users', user.value.uid)
      unsubscribeUserUpdates()
      unsubscribeUserUpdates = onSnapshot(
        userDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            dbUser.value = docSnapshot.data() as DatabaseUser
            score.value = dbUser.value.score ?? 0
            console.log('User data: ', dbUser.value)
          } else {
            console.log('No such user!')
          }
        },
        (error) => {
          if (error.code === 'permission-denied') {
            // Happens when logging out
            console.log('Missing or insufficient permissions')
          } else {
            console.error('An error occurred: ', error)
          }
        }
      )
    }
  }

  async function setSetting(key: string, value: any) {
    if (dbUser.value === null || user.value === null) {
      return
    }
    // @ts-ignore
    switch (key) {
      case 'vocabHideLearned':
        dbUser.value.vocabHideLearned = !!value
        break
      case 'silentMode':
        dbUser.value.silentMode = !!value
        break
      default:
        throw new Error('Invalid key')
    }
    const userDocRef = doc(db, 'users', user.value.uid)
    await updateDoc(userDocRef, {
      [key]: value
    })
  }

  return {
    score,
    isLoading,
    hasFailed,
    user,
    dbUser,
    hideLearnedWords,
    error: localError,
    loginInWithGoogle,
    loginWithApple,
    watchAuthState,
    unwatchAuthState,
    subscribeToUserUpdates,
    logout,
    setSetting
  }
})
