import { db } from '@/firebase/init'
import type { DatabaseWord } from '../../types/custom-types'
import {
  collection,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  where,
  onSnapshot
} from 'firebase/firestore'
import { useAuthStore } from '@/stores/auth'
import { getAnalytics, logEvent } from 'firebase/analytics'
import {INTERVAL_TO_DAY} from '@/config'
import {isLearned} from "@/utils";


// Read: Fetch all words from Firestore
export const getWords = async (isOrderedByScore = false): Promise<DatabaseWord[]> => {
  const auth = useAuthStore()

  const q = query(
    collection(db, 'words'),
    where('userRef', '==', doc(db, 'users/' + auth.user!.uid))
  )

  const querySnapshot = await getDocs(q)
  let wordList = querySnapshot.docs.filter(
    (doc) => !!(doc.data() as DatabaseWord).word
  )
  .map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
      isLearned: isLearned(doc.data() as DatabaseWord)
    } as DatabaseWord
  })
  if (isOrderedByScore) {
    wordList = wordList.sort((a, b) => {
      return a.sm2interval - b.sm2interval
    })
  }
  return wordList
}

// Create: Add a new word to Firestore
export const createWord = async (word: DatabaseWord): Promise<void> => {
  const auth = useAuthStore()

  word.userRef = doc(db, 'users/' + auth.user!.uid)
  word.sm2interval = 1
  word.sm2ease = 1.5
  await addDoc(collection(db, 'words'), word)

  const analytics = getAnalytics()
  logEvent(analytics, 'word_add')

  // This not very efficient.
  await removePreparedSession()
}

// Update: Update a word in Firestore
export const updateWord = async (word: DatabaseWord): Promise<void> => {
  if (!word.id) return
  const wordRef = doc(db, 'words', word.id)
  delete word.id
  delete word.lastAnswer
  delete word.answers
  await updateDoc(wordRef, word as any)
  await removePreparedSession()
  const analytics = getAnalytics()
  logEvent(analytics, 'word_update')
}

// Delete: Remove a word from Firestore
export const deleteWord = async (id: string): Promise<void> => {
  const wordRef = doc(db, 'words', id)
  await deleteDoc(wordRef)
  await removePreparedSession()
  const analytics = getAnalytics()
  logEvent(analytics, 'word_delete')
}

// Hide word by setting the hidden flag to true
export const hideWord = async (id: string, hide: boolean): Promise<void> => {
  const wordRef = doc(db, 'words', id)
  await updateDoc(wordRef, { hidden: hide })
}

export async function removePreparedSession() {
  const auth = useAuthStore()
  const sessionDocRef = doc(db, 'preparedSessions', auth.user!.uid)
  await deleteDoc(sessionDocRef)
}
