<script setup lang="ts">
import {nextTick, onMounted, onUnmounted, ref} from 'vue'

const props = defineProps<{
  stream: MediaStream
}>()

const interval = 50

let audioContext: AudioContext
let analyser: AnalyserNode
let source: MediaStreamAudioSourceNode
let timer: number | null

defineExpose({
  visualizeAmplitude,
  start,
  stop
})

const emits = defineEmits(['sound'])

function visualizeAmplitude() {
  const dataArray = new Uint8Array(analyser.frequencyBinCount)
  analyser.getByteFrequencyData(dataArray)
  const maxAmplitude = Math.max(...dataArray)
  emits('sound', maxAmplitude / 255)
}

function start() {
  timer = window.setInterval(visualizeAmplitude, interval)
}

function stop() {
  window.clearInterval(timer as number)
}

onUnmounted(() => {
  window.clearInterval(timer as number)
})

onMounted(() => {
  // Set up the Web Audio API context and analyser
  // @ts-ignore
  audioContext = new (window.AudioContext || window.webkitAudioContext)()
  analyser = new AnalyserNode(audioContext, {
    fftSize: 2048,
    smoothingTimeConstant: 0.3
  })
  source = audioContext.createMediaStreamSource(props.stream)
  source.connect(analyser)
})
</script>
