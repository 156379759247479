<script setup lang="ts">
import Card from 'primevue/card'
import { onMounted, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import type { DatabaseWord } from '@/../types/custom-types'
import type { User } from 'firebase/auth'
import { getWords} from '@/services/wordService'
import InfoTooltip from '@/components/InfoTooltip.vue'
import RandomIllustration from '@/components/Home/RandomIllustration.vue'
import {isLearned} from "@/utils";

const scoreStore = useAuthStore()

const { score, user } = storeToRefs(scoreStore) as unknown as { score: number; user: User }

const words = ref<DatabaseWord[]>([])
const bestWord = ref<string | null>(null)
const worstWord = ref<string | null>(null)
const learnedWords = ref<number>(0)

onMounted(async () => {
  if (user.uid === null) return
  words.value = await getWords(true)
  if (words.value.length === 0) return
  bestWord.value = words.value[words.value.length - 1].word
  worstWord.value = words.value[0].word
  learnedWords.value = words.value.filter((word) => isLearned(word)).length
})
</script>

<template>
  <h1>Welcome, {{ user!.displayName?.split(' ')[0] }}.</h1>

  <div class="grid grid-nogutter my-2 w-full">
    <Card class="col-6 bg-gray-50" @click="$router.push('/words')">
      <template #title>
        <span class="text-4xl">{{ words.length }}</span>
      </template>
      <template #content>
        Items in your Vocabulary
      </template>
    </Card>
    <Card class="col-6 bg-gray-50" @click="$router.push('/chart')">
      <template #title>
        <span class="text-4xl">{{ score }}</span>
      </template>
      <template #content>
        Points collected
        <InfoTooltip text="The faster you provide a correct answer, the more points you collect" />
      </template>
    </Card>
    <template v-if="bestWord">
      <Card class="col-6 my-2 bg-gray-50">
        <template #title>
          <span class="text-4xl">{{ learnedWords }}</span>
        </template>
        <template #content
          >Words learned
          <InfoTooltip text="A learned word is a word which you have used enough successfully" />
        </template>
      </Card>
      <Card class="col-6 my-2 bg-gray-50">
        <template #title>
          <span class="text-2xl">{{ bestWord }}</span>
        </template>
        <template #content>
          Your strongest item
          <InfoTooltip
            text="The item where you need the least amount practice according to the SuperMemo2 Algorithm"
          />
        </template>
      </Card>
    </template>
  </div>

  <Button
    v-if="words.length > 0"
    class="w-full my-2 flex-grow-1 justify-content-center"
    icon="pi pi-play"
    @click="() => $router.push('/practice')"
    >Start a Practice
  </Button>
  <Button
    class="w-full my-2 flex-grow-1 justify-content-center"
    icon="pi pi-pencil"
    @click="() => $router.push('/words')"
    >{{ words.length > 0 ? 'Manage Vocab' : 'Add Vocab Item' }}
  </Button>
  <RandomIllustration />
</template>

<style scoped></style>
